@import "../../styles/variables.scss";
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.tv-control {
  margin: 12px 0px;
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
}

.tv-checkbox .custom-control-label::before {
  border-radius: 0.12rem;
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #e9ecef;
  box-shadow: none;
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
  box-sizing: border-box;
}

.tv-checkbox .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font: 400 16px/20px $PRIMARY_FONT !important;
  background: #d2d2d2;
  color: #252525;
  padding: 3px 12px 1px;
  border-radius: 30px;
  text-transform: capitalize;

  &:before {
    width: 20px;
    height: 20px;

    top: 0.1rem;
  }

  &.label {
    &--accepted::before {
      border-color: $COLOR_STATUS_ACCEPTED !important;
    }

    &--fullfilled::before {
      border-color: $COLOR_STATUS_FULLILLED !important;
      color: #fff;
    }

    &--cancelled::before {
      color: #fff;
      border-color: $COLOR_STATUS_CANCELLED !important;
    }

    &--available::before {
      border-color: $COLOR_STATUS_AVAILABLE !important;
    }

    &--rejected::before {
      border-color: $COLOR_STATUS_REJECTED !important;
    }

    &--Nyregistrerad::before {
      border-color: $COLOR_STATUS_NEWREGISTERED !important;
    }

    &--Underutredning::before {
      border-color: $COLOR_STATUS_UNDERINVESTIGATE !important;
    }

    &--Avslutad::before {
      border-color: $COLOR_STATUS_COMPLETED !important;
    }
    &--ordered::before {
      border-color: $COLOR_STATUS_ACCEPTED !important;
    }
    &--notPerformed::before {
      border-color: $COLOR_STATUS_AVAILABLE !important;
    }
    &--interrupted::before {
      color: #fff;
      border-color: $COLOR_STATUS_CANCELLED !important;
    }
  }

  &:after {
    width: 20px;
    height: 20px;
    top: 0.1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  // filter: invert(1);
}

.tv-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef;
}

.tv-checkbox .custom-control-input:disabled:checked::before {
  border-color: #d2d2d2;
}

.tv-checkbox .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font-size: 0.875rem;
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d2d2d2;
  // background-color: #FFF;
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.tv-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef;
}

.tv-checkbox .custom-control-input:disabled:checked::before {
  border-color: #e9ecef;
}

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label {
  &--accepted::before {
    background-color: $COLOR_STATUS_ACCEPTED !important;
  }

  &--accepted::after {
    filter: invert(0) !important;
  }

  &--fullfilled::before {
    background-color: $COLOR_STATUS_FULLILLED !important;
  }

  &--cancelled::before {
    background-color: $COLOR_STATUS_CANCELLED !important;
  }

  &--available::before {
    background-color: $COLOR_STATUS_AVAILABLE !important;
  }

  &--rejected::before {
    background-color: $COLOR_STATUS_REJECTED !important;
  }

  &--Nyregistrerad::before {
    background-color: $COLOR_STATUS_NEWREGISTERED !important;
  }

  &--Underutredning::before {
    background-color: $COLOR_STATUS_UNDERINVESTIGATE !important;
  }

  &--Avslutad::before {
    background-color: $COLOR_STATUS_COMPLETED !important;
  }

  &--fullfilled::after {
    filter: invert(0) !important;
  }

  &--cancelled::after {
    filter: invert(0) !important;
  }

  &--available::after {
    filter: invert(0) !important;
  }

  &--rejected::after {
    filter: invert(0) !important;
  }

  &--Nyregistrerad::after {
    filter: invert(0) !important;
  }

  &--UnderUtredning::after {
    filter: invert(0) !important;
  }

  &--Avslutad::after {
    filter: invert(0) !important;
  }

  &--ordered::before {
    background-color: $COLOR_STATUS_ACCEPTED !important;
  }

  &--ordered::after {
    filter: invert(0) !important;
  }

  &--notPerformed::before {
    background-color: $COLOR_STATUS_AVAILABLE !important;
  }

  &--notPerformed::after {
    filter: invert(0) !important;
  }

  &--interrupted::before {
    background-color: $COLOR_STATUS_CANCELLED !important;
  }

  &--interrupted::after {
    filter: invert(0) !important;
  }

  &--altService::before {
    background-color: $PRIMARY_COLOR !important;
  }

  &--altService::after {
    filter: invert(0) !important;
  }
}

// color
// $PRIMARY_COLOR: #20175b;
$PRIMARY_COLOR: #873bff;
// $PRIMARY_HOVER_COLOR: #474b90;
$PRIMARY_HOVER_COLOR: $PRIMARY_COLOR;
// $SECONDARY_COLOR: #322878;
$SECONDARY_COLOR: $PRIMARY_COLOR;
$PURPLE_COLOR: #231950;
$TEXT_COLOR: #323232;
$TEXT_INACTIVE_COLOR: #969696;
$PURPLE_BLOOD_COLOR: #e1e6ff;
$BACKGROUND_COLOR: #f7f9ff;
$SIDE_DRAWER_BACK_COLOR: #fbfbfb;
$NAV_BAR_HOVER_COLOR: #fafafa;
$NAV_BAR_TEXT_COLOR: #313131;
$WHITE_COLOR: #fff;
$BLACK_COLOR: #000;
$TITLE_COLOR: #f9f9f9;
$TEXT_TITLE_COLOR: #353535;
$LIGHT_PINK_COLOR: #ffd2cd;
$PINK_COLOR: #ff9ba0;
// $BRIGHT_PINK_COLOR: #fa0a5f;
$BRIGHT_PINK_COLOR: $PRIMARY_COLOR;
$PURPLE_BLUE_COLOR: #6478e6;
$TAB_SELECTED_COLOR: $PRIMARY_COLOR;

$DARK_GRAY_COLOR: #a9a9a9;
$DARK_GRAY_COLOR-1: #f7f7f7;
$DARK_GRAY_COLOR-2: #c4c4c4;
$FONT_LIGHT_GRAY_COLOR: #74737b;
$COMMON_TEXT_COLOR: #252525;
$BORDER_COLOR: #e0e0e0;
$BGCOLOR-ICON: #d1d1d1;
// fonts
$PRIMARY_FONT: 'Circular Std', 'Circular Std';
$SECONDARY_FONT: 'Circular Std', 'Circular Std';

$SCROLLBAR_WIDTH: 10px;
$LIGHT_GRAY_COLOR: #d8d8d8;

// Status Color
$COLOR_STATUS_AVAILABLE: #ff9b4e;
$COLOR_STATUS_ACCEPTED: #6478e6;
$COLOR_STATUS_REJECTED: #e94242;
$COLOR_STATUS_FULLILLED: #18ad79;
$COLOR_STATUS_CANCELLED: #74737b;

$COLOR_STATUS_SUBMITTED: #008000;
$COLOR_STATUS_WORKING: #fbbcdb;
$COLOR_STATUS_DONE: #ffa500;
$COLOR_STATUS_LOSTED: #696969;
$COLOR_STATUS_CUSTOM: #9c69ad;

// Deviation Status Color
$COLOR_STATUS_NEWREGISTERED: #ffa500;
$COLOR_STATUS_UNDERINVESTIGATE: #007bff;
$COLOR_STATUS_COMPLETED: #008000;

// Navigation
$COLOR_NAVIGATION_ITEM: #8f8f8f;
$COLOR_NAVIGATION_ACTIVE_ITEM: $PRIMARY_COLOR;

// Feedback
$COLOR_REPORT_STATUS: #414141;

// Sub-Satus Color
$COLOR_SUBSTATUS_SEARCHING_FOR_INTERPRETER: rgba(255, 155, 78, 0.32);
$COLOR_SUBSTATUS_INTERPRETER_ACCEPTED: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_ORDER_IN_PROGRESS: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_ASSIGNING_INTERPRETER: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_AWAITING_TIME_REPORT: rgba(24, 173, 121, 0.32);
$COLOR_SUBSTATUS_TIME_REPORT_RECEIVED: rgba(24, 173, 121, 0.32);
$COLOR_SUBSTATUS_DEVIATION_REGISTERED: #74737b;
$COLOR_SUBSTATUS_ORDER_CANCELLED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_ORDER_REJECTED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_UNEXPECTED_EVENT_OCCURED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_UNKNOWN_STATUS: #fff;

// Label Color
$COLOR_TEXT_LABEL: #f4d0dd;
$COLOR_TEXT_LABEL_FONT: #20175b;

@import "../../../Share/styles/variables.scss";

.tv-main-dashboard {
  &__panel {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &__container {
    padding: 20px 40px;
  }
  &__title-container {
    display: flex;
    justify-content: center;
  }
  &__title {
    font: 500 26px/30px $PRIMARY_FONT;
    // background-color: $PRIMARY_COLOR;
    color: $PRIMARY_COLOR;
    padding: 40px 100px;
    border-radius: 10px;
    letter-spacing: 0.5px;
  }
  &__content {
    display: grid;
    grid-template-columns: auto auto auto;
    padding-top: 40px;
    gap: 20px;
  }
  &__card {
    // background-color: $PRIMARY_COLOR;
    background-color: #dbd2fc;
    color: $WHITE_COLOR;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    &-title {
      display: flex;
      flex-direction: column;
      gap: 20px;
      i {
        font-size: 25px;
        // color: $BRIGHT_PINK_COLOR;
        // color: white;
        color: $PRIMARY_COLOR;
      }
      h4 {
        font: 500 20px/28px $PRIMARY_FONT;
        color: $PRIMARY_COLOR;
      }
    }
    &-items {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      a {
        &:hover {
          color: $WHITE_COLOR;
        }
      }
    }
    &-item {
      background-color: $BRIGHT_PINK_COLOR;
      color: $WHITE_COLOR;
      font: 500 18px/22px $PRIMARY_FONT;
      border-radius: 4px;
      padding: 5px 0 5px 20px;
      cursor: pointer;
    }
  }
}

@import '../../../Share/styles/variables.scss';

.chatbot-container {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  z-index: 10000;

  .chatbot-button {
    line-height: 12px;
    font-family: 'Circular Std', 'Circular Std';
    font-size: 14px;
    background: $PRIMARY_COLOR;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 8px 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.2s;

    img {
      margin-right: 8px;
      width: 22px;
    }

    &:hover {
      filter: contrast(2);
    }
  }

  .chatbot-wrapper {
    width: 400px;
    border-radius: 16px;
    overflow: hidden;

    .chatbot-header {
      display: flex;
      align-items: center;
      background-color: $PRIMARY_COLOR;
      padding: 8px;

      .chatbot-header-title {
        flex: 1;
        text-align: center;
        color: $BACKGROUND_COLOR;
        font-size: 16px;
        font-weight: 500;
      }

      .chatbot-header-close {
        button {
          border: 0;
          outline: 0;
          background: none;
          transition: ease-in-out 0.2s;
          border-radius: 4px;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }

    .chatbot-body {
      background-color: #edf0ff;

      .chat-display-section {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 65vh;
        overflow: auto;

        .receiver {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          align-self: flex-start;

          .receiver_avatar {
            width: 32px;
          }

          .receiver_msg {
            background-color: $BACKGROUND_COLOR;
            padding: 8px 16px;
            border-radius: 0 16px 16px 16px;
            font-size: 16px;
            font-weight: 400;
          }
        }

        ._multiple {
          width: calc(100% - 40px);
          position: relative;
          left: 40px;

          .receiver_msg {
            padding: unset;
          }

          .receiver-header {
            background-color: #6478e6;
            padding: 8px 16px;
            border-radius: 0 16px 0 0;
            color: #ffffff;
          }

          .initilal-questions {
            padding: 12px 0;
            border-bottom: 1px solid #322878;
            padding: 12px 16px;
            cursor: pointer;
            transition: ease-in-out 0.2s;
            font-size: 16px;
            text-decoration: underline;

            &:last-child {
              border: none;
            }

            &:hover {
              background-color: #f1f1f1;
            }
          }
        }

        .sender {
          background-color: $PURPLE_COLOR;
          border-radius: 16px 16px 0 16px;
          padding: 8px 16px;
          color: $BACKGROUND_COLOR;
          font-size: 16px;
          font-weight: 400;
          align-self: flex-end;
        }
      }

      .chat-send-section {
        padding: 16px;
        border-top: 1px solid #a7a7a7;
        display: flex;
        align-items: center;
        gap: 10px;

        .msg_input {
          border: 0;
          outline: 0;
          background: transparent !important;
          flex: 1;
          border-radius: 4px;
          padding: 6px;

          &:focus {
            outline: 1px solid $PURPLE_COLOR;
          }
        }

        .send_msg_btn {
          border: 0;
          outline: 0;
          background: $PURPLE_COLOR;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: ease-in-out 0.2s;

          &:hover {
            filter: contrast(2);
          }
        }
      }
    }
  }
}

@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
.contact_card_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 230px;
  width: 100%;
  height: auto;
}
.contact_card__item:first-of-type {
  margin-right: 16px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
}
:root {
  --page-margin: max(25px, min(5%, 25px));
}
.page-title {
  display: flex;
  font: 400 28px /35px $PRIMARY_FONT;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    font: 400 24px /30px $PRIMARY_FONT;
    flex-direction: column;
  }
}

.page-section-body {
  font: 100 16px /24px $PRIMARY_FONT;
  margin-top: 20px;
  text-align: justify;

  a {
    color: #e94242;
  }
}

.title {
  width: 50%;
}
.page-footer {
  display: flex;
  position: absolute;
  left: 0;
  height: 160px;
  bottom: 0;
  justify-content: center;
  background-color: #f8f8f8;
  width: 100%;
  img {
    float: right;
    margin: 0 32px 40px 0;
  }
  @media screen and (max-width: 768px) {
    height: 94px;
    justify-content: center;
    img {
      float: unset !important;
      margin: 0 auto 32px;
    }
  }
}

.generic_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
  min-width: 300px;
  width: 100%;
  min-height: 320px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    height: 150px;
    border-radius: 4px;
    background: #fff;
    width: auto;
  }
}
.contact_image {
  min-height: 276px;
  @media screen and (max-width: 768px) {
    min-height: 180px;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
  img {
    width: 80%;
    height: auto;
    object-fit: cover;
  }
}
.contact_city {
  padding-top: 15px;
  margin-bottom: 8px;
  margin-top: 16px;
  font: 24px/30px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    font: 24px/30px $PRIMARY_FONT;
  }
}

.contact_address_wrapper {
  font: 16px/20px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
}
.contact_method_wrapper {
  padding-top: 15px;
  display: inline-flex;
  padding-bottom: 15px;
  font: 16px/20px $PRIMARY_FONT;

  &-number a {
    color: $PRIMARY_COLOR;
    margin-right: 5px;
    ::before {
      content: ':';
      color: $COMMON_TEXT_COLOR;
    }
  }
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
}
.faq_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 0 72px 0;
  @media screen and (max-width: 768px) {
    margin: 0px 0 0px 0;
  }
}

.drop_accordian {
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  border: 1px solid $BORDER_COLOR;
  font: 16px/20px 'Circular Std', sans-serif;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.question_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 500 19px/24px 'Circular Std', sans-serif;
  padding: 0px 22px 0px 24px;
  :hover {
    cursor: pointer;
  }
}
.heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 500;
  justify-content: space-between;
  padding: 24px 0 24px 0;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.icon_wrapper {
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.icon {
  font-size: 16px;
}

.body {
  display: flex;
  font: 16px/20px 'Circular Std', sans-serif;
  flex-direction: column;
  p {
    margin-bottom: 0;
    margin-top: 16px;
    font-weight: 100;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}

.page_content {
  color: $COMMON_TEXT_COLOR;
  display: flex;
  flex-direction: column;
  margin: 0 var(--page-margin);
  @supports not (margin: var(--page-margin)) {
    margin: 0 16px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  overflow: hidden;
}
.contact_now_button {
  font-family: $PRIMARY_FONT;
  background: $PRIMARY_COLOR;
  border: none;
  outline: none;
  color: white;
  padding: 14px;
  width: fit-content;
  border-radius: 6px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.contact_now_button_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.tv-reportquality {
  &__improvementsuggest-modal {
    top: 0 !important;
    left: 0 !important;
    height: 100vh !important;
    width: 100vw !important;
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);

    .tv-modal__holder {
      background-color: $WHITE_COLOR;
      border-radius: 8px;
      padding: 20px;
      margin: 0 auto;
      text-align: left;
      max-height: max-content;
      width: 500px;
      .tv-modal__btn-close {
        display: none;
      }

      .tv-modal__content {
        padding: 16px;
        font-size: 16px;
        line-height: 24px;
        color: $BLACK_COLOR;
      }

      &-form {
        width: 100%;

        &__paragraph {
          padding: 7px 15px 2px;
        }

        &__label {
          font-weight: 500;
          font-family: $PRIMARY_FONT;
        }

        &__textfield {
          padding: 14px 20px;
        }

        &__dropdownlist {
          padding: 7px 13px 5px 13px;
        }

        &__textarea {
          height: 130px;
        }

        &__buttons {
          margin: 0 16px 20px;
        }

        &__cancel {
          border-radius: 6px;
          background: none;
          color: #000000;
          font-weight: 500;
          border: 2px solid #c4c4c4;
          font-weight: 500;
        }

        &__submit {
          border: unset;
          border-radius: 6px;
          width: 100%;
          padding: 10px;
          color: #252733;
          background: #c2c7ce;
          font-weight: 500;
        }

        &-mobiletitle {
          text-align: center;
          font-weight: 600;
          font-size: 35px;
          font-family: $PRIMARY_FONT;
        }
      }
    }

    .tv-form__control--error {
      margin-bottom: 0;
      border: 2px solid red !important;
    }

    .tv-form__error:empty {
      display: none;
    }

    textarea.tv-single-assign-form__control {
      height: auto !important;
      padding: 16px !important;
      max-height: 120px !important;
      min-height: 48px;
    }
  }
}

@import "../../../Share/styles/variables.scss";

.timereport {
  padding: 0 32px 24px 32px;
  width: 100%;
  background-color: $WHITE_COLOR;
  display: flex;
  flex-direction: column;
  &__modal {
  }

  &__general-details {
    .assignment-number {
      font: 500 16px/20px $PRIMARY_FONT;
      margin-bottom: 10px;
    }
    .assignment-schedule-time {
      font: 400 14px/20px $PRIMARY_FONT;
    }
  }

  .star-group {
    display: flex;
    justify-content: center;
    & .react-stars span {
      margin: 0 5px;
      font-size: 30px !important;
    }
  }

  & .form-group {
    & label {
      font: 500 16px/20px $PRIMARY_FONT;
    }
    input,
    select {
      font: 400 16px/20px $PRIMARY_FONT !important;
      height: 48px !important;
    }
  }

  & .tv-form__date-field {
    font: 400 16px/20px $PRIMARY_FONT !important;
    padding: 14px 16px !important;

    &:after {
      top: 12px !important;
    }
  }

  & .dropdown {
    height: 42px;

    & .rc-time-picker-input {
      height: inherit;
      font: 400 16px/20px $PRIMARY_FONT !important;
      &::-ms-clear {
        display: none;
      }
    }

    ul.panel {
      scroll-behavior: smooth;
      padding-left: 15px;
      top: 50px;
      li {
        font: 100 13px/16px $PRIMARY_FONT !important;
        height: 40px;
        padding: 14px 0;
      }
    }
    & .rc-time-picker-select-option {
      height: 48px;
      width: 30px;
      position: absolute;
      right: 0;
      cursor: pointer;

      &::after {
        content: "";
        top: 20px;
        display: inline-block;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid #888888;
      }
    }
  }
}

// width
$pannelLeftWidth: 250px;
$pannelRightWidth: 1190px;
$leftWidthPercent: $pannelLeftWidth / ($pannelLeftWidth + $pannelRightWidth) *
  100%;
$rightWidthPercent: $pannelRightWidth / ($pannelLeftWidth + $pannelRightWidth) *
  100%;
$maxWidthConntainer: $pannelLeftWidth + $pannelRightWidth;

@mixin borderTopOnMobile {
  border-top: 1px dashed black;
}

*,
body {
  font-family: 'Circular Std';
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $BACKGROUND_COLOR;
  margin: 0;
  color: #252525;
}

// border-box
html {
  box-sizing: border-box;
  font-size: 16px;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: $WHITE_COLOR;
}

*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 1em 0;
  padding: 0;
}

.tv-container {
  margin: 0 auto;
}

.tv-capitalize {
  text-transform: capitalize;
}

.tv-uppercase {
  text-transform: uppercase;
}

.tv-common-fs {
  font-size: 0.875rem;

  & button,
  li,
  span {
    font-size: 0.875rem !important;
  }
}

.tv {
  &-headline-0 {
    font-family: $PRIMARY_FONT;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 54px;
  }

  &-headline-1 {
    font-family: $PRIMARY_FONT;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 40px;
  }

  &-headline-2 {
    font-family: $PRIMARY_FONT;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
  }

  &-title-text {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 28px;
    &.bold {
      font-weight: 600;
    }
    .copy-to-clipboard {
      cursor: pointer;
    }
  }

  &-booking-success {
    display: grid;
    grid-template-columns: auto auto;
    gap: 25px;
    padding: 25px 20px 0 20px;
    color: #74737B;
    justify-content: space-around;
    font: 400 16px $PRIMARY_FONT;
    &-item {
      display: flex;
      gap: 10px;
    }
  }

  &-body-text {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    line-height: 24px;
  }

  &-small-text {
    font-family: $PRIMARY_FONT;
    font-size: 14px;
    line-height: 24px;
  }

  &-display-flex {
    display: flex;

    &-coloumn {
      flex-direction: column;
    }
  }

  &-position-rel {
    position: relative;
  }

  &-w-50 {
    width: 50%;
  }

  &-w-100 {
    width: 100%;
  }

  // tv: prefix for transvoice
  &-primary-color {
    color: $PRIMARY_COLOR;
  }

  &-secondary-color {
    color: $SECONDARY_COLOR;
  }

  &-text-color {
    color: $TEXT_COLOR;
  }

  &-text-inactive-color {
    color: $TEXT_INACTIVE_COLOR;
  }

  &-space {
    &-40 {
      padding-right: 40px;
    }
  }
}

[role='button'],
[class^='tv-buttons__type'],
[class*=' tv-buttons__type'] {
  cursor: pointer;
  outline: none;
  font-size: 16px;
}

.tv-border-radius-shadow {
  background: $WHITE_COLOR;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
}

.tv-bottom-line {
  border-top: 1px solid #f1f1f1;
}

.tv-is-clipped {
  overflow: scroll;
  overflow-x: hidden;
}

.tv-is-clipped2 {
  overflow: hidden;
}

.tv-loading {
  &__wrapper {
    &:before {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      content: '';
      background: rgba(0, 0, 0, 0.3);
      z-index: 10000;
    }
  }

  &__content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10010;
  }

  &__spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 6px;
    border-color: $WHITE_COLOR;
    border-top-color: $PRIMARY_COLOR;
    border-bottom-color: $PRIMARY_COLOR;
    animation: spin 1.5s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
}

.m-auto {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.tv-modal {
  &__wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__overlay {
    content: '';
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 12;
  }

  &__holder {
    max-height: calc(100vh - 70px);
    z-index: 20;
    padding: 0 10px;
    animation: zoomIn 0.5s;
    max-width: calc(100vh - 100px);
    overflow-y: auto;
    position: fixed;
    left: calc(50% - 310px);
    @supports (display: flex) {
      left: unset;
    }
  }

  &__btn-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4;
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.tv-row {
  @include make-row();
}

.tv-row > [class^='tv-col-'] {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

@mixin make-col($size, $columns: 12) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@for $i from 1 through 12 {
  .tv-col-#{$i} {
    @include make-col($i, 12);
  }
}

.tv-detail-customer-name {
  font: 1rem 'Circular Std', sans-serif;
  color: #a9a9a9;
}

.rate-detail-box {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: auto;
  font: 1rem 'Circular Std', sans-serif;
  background-color: white;

  & > span {
    margin: 5px 0px;
    font: 0.875rem 'Circular Std', sans-serif;
  }
}

.disabled {
  color: darkgrey;

  &:after {
    color: darkgrey !important;
  }

  &:before {
    color: darkgrey !important;
  }
}
.text-box-content {
  min-height: 70px;
}
input,
select {
  background-color: $WHITE-COLOR !important;
}
.weekend-background {
  @supports (display: grid) {
    background-color: #f1f4ff !important;
  }
}
.primary-color {
  color: $PRIMARY_COLOR;
}

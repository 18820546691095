@import '../styles/variables.scss';

.tv-buttons {
  &__normal {
    border: 0;
    background: transparent;
  }

  &__element {
    padding: 11px 10px 9px;
    font-family: $PRIMARY_FONT;
    border-radius: 5px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    outline: none;

    &:hover {
      text-decoration: none;
    }

    &--primary {
      color: $WHITE_COLOR;
      background: $PRIMARY_COLOR;
      border: 1px solid $PRIMARY_COLOR; // set border to avoid change size of button

      &.tv-buttons__element--disable {
        border: 1px solid $TEXT_INACTIVE_COLOR;
      }
    }

    &--secondary {
      color: $WHITE_COLOR;
      background: $PRIMARY_COLOR;
    }

    &--line {
      &-default {
        border: 1px solid $BORDER_COLOR;
        color: $WHITE_COLOR;
      }

      &-primary {
        color: $COMMON_TEXT_COLOR;
        border: 1px solid $BORDER_COLOR;
      }

      &-secondary {
        color: $NAV_BAR_TEXT_COLOR;
        border: 1px solid $TEXT_COLOR;
      }
    }

    &--disable {
      cursor: context-menu;
      color: $WHITE_COLOR;
      background: $TEXT_INACTIVE_COLOR;
    }

    &--text {
      color: $PRIMARY_COLOR;
    }

    &--icon {
      padding: 6px 4px 2px;
      border-radius: 100%;
      font-size: 12px;
    }
  }

  &__type {
    background: transparent;
    border: 0;
    padding: 0;
  }
}

.tv-filter-list {
  &__element {
    position: relative;
    font-size: 0.875rem;
  }

  &__user-name {
    padding-left: 20px;
    color: $SECONDARY_COLOR;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: max-content;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -o-max-content;
    /* width: -ms-max-content;*/

    &.ie-fix {
      white-space: nowrap;
      width: auto;
    }
  }

  &__filter-count-badge {
    padding: 0px 5px;
    color: white;
    background-color: rgb(126, 118, 118);
    border-radius: 25px;
    width: 5px;
  }

  &__button {
    font-family: $SECONDARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    text-transform: uppercase;

    &:before {
      content: '\e900';
      @include tv-transvoice-font;
      color: #c5c5c5;
      margin-right: 9px;
    }

    &:after {
      margin-left: 8px;
      content: '\e933';
      @include tv-transvoice-font;
      color: $NAV_BAR_TEXT_COLOR;
      font-size: 0.8rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__menu {
    font-family: $SECONDARY_FONT;
    @extend .tv-border-radius-shadow;
    position: absolute;
    margin: 12px 0 0 0;
    padding: 0;
    width: max-content;

    &-item {
      border-bottom: 2px solid #f3f5f7;
      list-style: none;

      &:last-child {
        border-bottom: 0;
      }

      button {
        font-family: $SECONDARY_FONT;
        color: $NAV_BAR_TEXT_COLOR;
        padding: 18px 24px 15px 14px;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.tv-avatar-list {
  &__menu {
    background-color: $WHITE_COLOR;
    border: 1px solid #e0e0e0;
    box-shadow: 2px 1px 4px 0 hsla(0, 0%, 62%, 0.522);
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 16px;
    right: -8px;
    min-width: 300px;
    z-index: 100;
    text-align: left;
    padding-bottom: 16px;
    // Tooltip icon
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   right: 17px;
    //   margin-top: -16px;
    //   margin-left: 19px;
    //   border-width: 8px;
    //   border-style: solid;
    //   border-color: transparent transparent $WHITE_COLOR transparent;
    // }

    &-availability-btn {
      font: 300 rem-calc(16) / rem-calc(23) $PRIMARY_FONT;
      padding: 8px 20px;
      margin-top: 16px;
    }

    &-item {
      display: block;
      color: $PRIMARY_COLOR;
      display: block;
      padding: 8px 16px 8px 16px;
      font: 500 16px/20px $PRIMARY_FONT;
      &.active {
        background-color: #eeeeee;
      }

      &:hover {
        text-decoration: none;
        background-color: #eeeeee;
        color: $PRIMARY_COLOR;
      }

      &-icon {
        margin-right: 10px;
        font-size: 0.9375rem;
      }

      &--name {
        padding: 16px;
        font: 500 16px/20px $PRIMARY_FONT;
        border-bottom: 1px solid $BORDER_COLOR;

        &.tv-avatar-list__menu-item:hover {
          background-color: $WHITE_COLOR;
        }
      }

      &--customer-name {
        padding: 16px;
        font: 500 16px/20px $PRIMARY_FONT;
        border-bottom: 1px solid $BORDER_COLOR;
        margin-bottom: 8px;
        color: $COMMON_TEXT_COLOR;
        &.tv-avatar-list__menu-item:hover {
          background-color: $WHITE_COLOR;
        }
      }

      &--customer-number {
        font: rem-calc(14) / rem-calc(20) $SECONDARY_FONT;
        color: $DARK_GRAY_COLOR;
        letter-spacing: 0.3px;
        border-bottom: 1px solid #e5e5e5;

        &.tv-avatar-list__menu-item:hover {
          background-color: $WHITE_COLOR;
        }
      }

      &--id {
        color: $PRIMARY_COLOR;
        padding-top: 0px;
        padding-bottom: 17px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 0.875rem;

        &.tv-avatar-list__menu-item:hover {
          background-color: $WHITE_COLOR;
        }
      }

      &--profile {
        padding: 16px 16px 0 16px;
        font: 500 16px/20px $PRIMARY_FONT;
      }

      &--logout {
        padding: 8px 16px 8px 16px;
        font: 500 16px/20px $PRIMARY_FONT;
      }

      &--ondemand {
        &.active {
          background-color: transparent;
        }
      }
    }
  }
}

.tx-input-text {
  background-color: transparent;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ededed;
  padding: 10px 0px;

  font: 0.875rem/1.25rem $SECONDARY_FONT;
  color: $NAV_BAR_TEXT_COLOR;

  &:focus {
    border-bottom: 1px solid #6478e6;
  }

  &.tv-form__control--error {
    border-bottom: 1px solid red;
  }

  &--inactive {
    color: #a9a9a9;
    -webkit-text-fill-color: #a9a9a9;
    -webkit-opacity: 1;
  }

  &--border {
    border: 1px solid $BORDER_COLOR;
    border-radius: 4px;
    padding: 13px 20px;

    &:focus {
      border-color: $PRIMARY_COLOR;
    }
  }
}

.tv-toggle-button {
  display: inline-block;
  min-width: 145px;
  height: 49px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #6478e6;
  font-family: $SECONDARY_FONT;
  font-size: 14px;
  line-height: 49px;
  text-align: center;
  color: white;
  cursor: pointer;
  outline: none;

  &--phone {
    &:before {
      content: '\e91a';
      margin-right: 15px;
      @include tv-transvoice-font;
    }
  }

  &--onsite {
    &:before {
      content: '\e910';
      margin-right: 15px;
      @include tv-transvoice-font;
    }
  }

  &--unselected {
    border-radius: 5px;
    border: 1px solid #6478e6;
    background-color: white;
    color: #313131;

    &.tv-toggle-button--phone {
      &:before {
        color: #c5c5c5;
      }
    }

    &.tv-toggle-button--onsite {
      &:before {
        color: #c5c5c5;
      }
    }
  }
}

.tv-date-picker {
  height: 52px;
  width: 188px;
  padding: 16px 20px;
  border: 1px solid $BORDER_COLOR;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  font-family: 'PT Serif';
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  cursor: pointer;

  &--inactive {
    color: #a9a9a9;
  }

  &::after {
    content: '\e933';
    @include tv-transvoice-font;
    font-size: 10px;
    color: #c5c5c5;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: 20px;
    margin-top: 14px;
  }
}

.tv-attachment {
  width: 130px;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;

  &__header {
    height: 80px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &--doc,
    &--docx {
      background-color: #6478e6;

      &::after {
        content: '\e93a';
        @include tv-transvoice-font;
        font-size: 38px;
        color: $WHITE_COLOR;
      }
    }

    &--pdf {
      background-color: #ff9ba0;

      &::after {
        content: '\e93b';
        @include tv-transvoice-font;
        font-size: 40px;
        color: $WHITE_COLOR;
      }
    }

    &--image {
      background-color: #454c98;

      &::after {
        content: '\e940';
        @include tv-transvoice-font;
        font-size: 40px;
        color: $WHITE_COLOR;
      }
    }

    &--other {
      background-color: #454c98;

      &::after {
        content: '\e930';
        @include tv-transvoice-font;
        font-size: 40px;
        color: $WHITE_COLOR;
      }
    }
  }

  &__details {
    height: 40px;
    border: 1px solid #eaeaea;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #f8f8f8;

    color: $PRIMARY_COLOR;
    font: rem-calc(10) / rem-calc(40) $SECONDARY_FONT;
    text-align: center;

    &::before {
      content: '\e93c';
      @include tv-transvoice-font;
      font-size: 10px;
      color: #322978;
      margin-right: 8px;
    }
  }
}

.tv-file {
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  &__details {
    background-color: transparent;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    color: $PRIMARY_COLOR;
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;

    &::before {
      content: '\e91e';
      @include tv-transvoice-font;
      font-size: 25px;
      color: $PRIMARY_COLOR;
      margin-right: 8px;
    }

    &-text {
      text-decoration: underline;
    }
  }
}

.filter-title {
  margin-top: 27px !important;
  font-size: 0.875rem !important;
  font-weight: bold;
}

.tv-filter-container {
  &__menu {
    background-color: $WHITE_COLOR;
    box-shadow: 3px -5px 16px 0 rgba(159, 159, 159, 0.52);
    position: absolute;
    display: flex;
    font-size: 0.875rem;
    flex-direction: column;
    top: 38px;
    left: -8px;
    margin-top: 12px;
    padding-left: 20px;
    min-width: 250px;
    z-index: 100;
    font-family: $SECONDARY_FONT;

    // Tooltip icon
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -16px;
      margin-left: 19px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent $WHITE_COLOR transparent;
    }
  }

  &__action-container {
    display: flex;
    flex-direction: row;
  }

  &__sub-menu {
    min-width: max-content;
    width: 200px;
    margin-left: 5px;
    font-size: 0.875rem;

    & > span {
      text-transform: uppercase;
    }

    & > span > h4 {
      margin-block-end: 0em !important;
    }

    & > ul {
      padding-inline-start: 0px !important;
    }
  }

  &__active-filters {
    width: 100%;
    float: left;
    padding-left: 10px;

    & > button {
      border-radius: 4px;
      position: relative;
      border: 1px solid rgb(128, 122, 122);
      padding: 6px 30px 6px 10px;
      text-transform: uppercase;
      margin: 10px 10px 10px 0px;
      color: white;
      background: rgba(135, 127, 127, 0.823);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      user-select: none;
      transition: all 0.3s;

      &:after {
        content: 'x';
        position: absolute;
        color: #ffff;
        top: 5px;
        right: 6px;
      }

      &:hover {
        transform: scale(1.03);
        color: #000000fa;
        background: rgba(0, 0, 0, 0.13);
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
      }

      &:active {
        transform: scale(0.96);

        .badge {
          transform: scale(1.2);
        }
      }
    }
  }
}

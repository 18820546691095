@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700,700i&display=swap");
@import url("/font-awesome/css/all.min.css");
.icon-hearing:before {
  display: flex;
  -webkit-mask-image: url("/images/hearing-icon.svg");
  -webkit-mask-size: 16px;
  mask-size: 16px;
  mask-image: url("/images/hearing-icon.svg");
  background-color: #74737b;
  content: '';
  height: 16px;
  width: 16px; }
  @media (max-width: 768px) {
    .icon-hearing:before {
      background-size: 22px 22px;
      height: 20px;
      width: 20px;
      -webkit-mask-size: 20px;
      mask-size: 20px;
      padding: 5px; } }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Book.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'transvoice-icon';
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"), url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"), url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'transvoice-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: '\e900';
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: '\e901';
  color: #c5c5c5; }

.icon-warning:before {
  content: '\e902';
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: '\e903';
  color: #fff; }

.icon-trophy:before {
  content: '\e904';
  color: #bdbdbd; }

.icon-timer:before {
  content: '\e905';
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: '\e906';
  color: #c5c5c5; }

.icon-task:before {
  content: '\e907';
  color: #c5c5c5; }

.icon-submit-time:before {
  content: '\e908';
  color: #c5c5c5; }

.icon-small:before {
  content: '\e909';
  color: #bdbdbd; }

.icon-share:before {
  content: '\e90a';
  color: #c5c5c5; }

.icon-search:before {
  content: '\e90b'; }

.icon-schedule:before {
  content: '\e90c';
  color: #bdbdbd; }

.icon-report-quality:before {
  content: '\e90d';
  color: #c5c5c5; }

.icon-question:before {
  content: '\e90e';
  color: #c0c2c3; }

.icon-profile:before {
  content: '\e90f';
  color: #c5c5c5; }

.icon-pointer:before {
  content: '\e910';
  color: #c5c5c5; }

.icon-photo:before {
  content: '\e911';
  color: #9e9e9e; }

.icon-news:before {
  content: '\e912';
  color: #c5c5c5; }

.icon-lock:before {
  content: '\e913';
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: '\e914';
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: '\e915';
  color: #fff; }

.icon-user:before {
  content: '\e916';
  color: #bdbdbd; }

.icon-hourglass:before {
  content: '\e917';
  color: #c9c9c9; }

.icon-home:before {
  content: '\e918';
  color: #c5c5c5; }

.icon-handset:before {
  content: '\e919';
  color: #c5c5c5; }

.icon-handset-2:before {
  content: '\e91a';
  color: #c5c5c5; }

.icon-flag:before {
  content: '\e91b';
  color: #bdbdbd; }

.icon-fire:before {
  content: '\e91c';
  color: #fff; }

.icon-drawing-pin:before {
  content: '\e91d';
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: '\e91e';
  color: #c0c2c3; }

.icon-dollar:before {
  content: '\e91f';
  color: #fff; }

.icon-dollar-2:before {
  content: '\e920';
  color: #d1d1d1; }

.icon-create-new:before {
  content: '\e921';
  color: #fff; }

.icon-contact:before {
  content: '\e922';
  color: #c5c5c5; }

.icon-contact-2:before {
  content: '\e923';
  color: #c5c5c5; }

.icon-comment:before {
  content: '\e924';
  color: #c0c2c3; }

.icon-comment-2:before {
  content: '\e925';
  color: #fff; }

.icon-close:before {
  content: '\e926';
  color: #313131; }

.icon-next:after {
  content: '\e931';
  color: #313131; }

.icon-previous:before {
  content: '\e932';
  color: #313131; }

.icon-certificate:before {
  content: '\e927';
  color: #bdbdbd; }

.icon-camera:before {
  content: '\e928';
  color: #20175b; }

.icon-calendar:before {
  content: '\e929';
  color: #a5a4a4; }

.icon-browse-assignments:before {
  content: '\e92a';
  color: #c5c5c5; }

.icon-book:before {
  content: '\e92b';
  color: #c5c5c5; }

.icon-book-2:before {
  content: '\e92c';
  color: #c0c2c3; }

.icon-bell:before {
  content: '\e92d';
  color: #c5c5c5; }

.icon-available-request:before {
  content: '\e92e';
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: '\e92f';
  color: #fff; }

.icon-attach:before {
  content: '\e930';
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: '\e931';
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: '\e932';
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: '\e933'; }

.icon-arrow-down:after {
  content: '\e933'; }

.icon-arrow-top:after {
  content: '\e931';
  display: inline-block;
  transform: rotate(-90deg); }

.icon-arrow-back:before {
  content: '\e934'; }

.icon-settings:before {
  content: '\e935';
  color: #c5c5c5; }

.icon-envelope:before {
  content: '\e936';
  color: #c5c5c5; }

.icon-log-out:before {
  content: '\e937';
  color: #20175b; }

.icon-more:before {
  content: '\e938';
  color: #c5c5c5; }

.icon-check:before {
  content: '\e939';
  color: #c5c5c5; }

.icon-doc:before {
  content: '\e93a';
  color: #c5c5c5; }

.icon-pdf:before {
  content: '\e93b';
  color: #c5c5c5; }

.icon-download-file:before {
  content: '\e93c';
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: '\e93d';
  color: #c5c5c5; }

.icon-single:before {
  content: '\e93e';
  color: #c5c5c5; }

.icon-multi:before {
  content: '\e93f';
  color: #c5c5c5; }

.icon-image-file:before {
  content: '\e940';
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: '\e941';
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: '\e942';
  color: #c5c5c5; }

.icon-edit:before {
  content: '\2639';
  color: '#000'; }

.icon-task-list:before {
  content: '\2630';
  color: #c5c5c5; }

html,
body {
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "PT Serif"; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

* {
  outline: none; }

a {
  text-decoration: none; }
  a:active, a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.bn_topbar a:hover {
  text-decoration: none; }

input {
  /*-webkit-appearance:none;*/
  box-sizing: border-box; }

.padding-15 {
  padding: 15px; }

.padding-30 {
  padding: 30px; }

.padding-45 {
  padding: 45px; }

.padding-50 {
  padding: 50px; }

.padding-right-15 {
  padding-right: 15px; }

.padding-right-30 {
  padding-right: 30px; }

.padding-right-45 {
  padding-right: 45px; }

.padding-right-50 {
  padding-right: 50px; }

.padding-left-15 {
  padding-left: 15px; }

.padding-left-30 {
  padding-left: 30px; }

.padding-left-45 {
  padding-left: 45px; }

.padding-left-50 {
  padding-left: 50px; }

.padding-top-15 {
  padding-top: 15px; }

.padding-top-30 {
  padding-top: 30px; }

.padding-top-45 {
  padding-top: 45px; }

.padding-top-50 {
  padding-top: 50px; }

.padding-bottom-15 {
  padding-bottom: 15px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.padding-bottom-45 {
  padding-bottom: 45px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

*,
body {
  font-family: 'Circular Std'; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  background-color: #f7f9ff;
  margin: 0;
  color: #252525; }

html {
  box-sizing: border-box;
  font-size: 16px; }

*::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

*::-webkit-scrollbar-track {
  background-color: #fff; }

*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 1em 0;
  padding: 0; }

.tv-container {
  margin: 0 auto; }

.tv-capitalize {
  text-transform: capitalize; }

.tv-uppercase {
  text-transform: uppercase; }

.tv-common-fs {
  font-size: 0.875rem; }
  .tv-common-fs button,
  .tv-common-fs li,
  .tv-common-fs span {
    font-size: 0.875rem !important; }

.tv-headline-0 {
  font-family: "Circular Std", "Circular Std";
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 54px; }

.tv-headline-1 {
  font-family: "Circular Std", "Circular Std";
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 40px; }

.tv-headline-2 {
  font-family: "Circular Std", "Circular Std";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 32px; }

.tv-title-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 28px; }
  .tv-title-text.bold {
    font-weight: 600; }
  .tv-title-text .copy-to-clipboard {
    cursor: pointer; }

.tv-booking-success {
  display: grid;
  grid-template-columns: auto auto;
  gap: 25px;
  padding: 25px 20px 0 20px;
  color: #74737B;
  justify-content: space-around;
  font: 400 16px "Circular Std", "Circular Std"; }
  .tv-booking-success-item {
    display: flex;
    gap: 10px; }

.tv-body-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  line-height: 24px; }

.tv-small-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 14px;
  line-height: 24px; }

.tv-display-flex {
  display: flex; }
  .tv-display-flex-coloumn {
    flex-direction: column; }

.tv-position-rel {
  position: relative; }

.tv-w-50 {
  width: 50%; }

.tv-w-100 {
  width: 100%; }

.tv-primary-color {
  color: #873bff; }

.tv-secondary-color {
  color: #873bff; }

.tv-text-color {
  color: #323232; }

.tv-text-inactive-color {
  color: #969696; }

.tv-space-40 {
  padding-right: 40px; }

[role='button'],
[class^='tv-buttons__type'],
[class*=' tv-buttons__type'] {
  cursor: pointer;
  outline: none;
  font-size: 16px; }

.tv-border-radius-shadow, .tv-filter-list__menu, .tv-youravailability__list-item, .tv-youravailability__list-item-drawer {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-bottom-line {
  border-top: 1px solid #f1f1f1; }

.tv-is-clipped {
  overflow: scroll;
  overflow-x: hidden; }

.tv-is-clipped2 {
  overflow: hidden; }

.tv-loading__wrapper:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000; }

.tv-loading__content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10010; }

.tv-loading__spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 6px;
  border-color: #fff;
  border-top-color: #873bff;
  border-bottom-color: #873bff;
  animation: spin 1.5s infinite linear;
  border-radius: 100%;
  border-style: solid; }

.m-auto {
  margin: auto; }

.w-100 {
  width: 100%; }

.tv-modal__wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0; }

.tv-modal__overlay {
  content: '';
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12; }

.tv-modal__holder {
  max-height: calc(100vh - 70px);
  z-index: 20;
  padding: 0 10px;
  animation: zoomIn 0.5s;
  max-width: calc(100vh - 100px);
  overflow-y: auto;
  position: fixed;
  left: calc(50% - 310px); }
  @supports (display: flex) {
    .tv-modal__holder {
      left: unset; } }

.tv-modal__btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px !important;
  height: 56px;
  z-index: 4; }

.tv-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.tv-row > [class^='tv-col-'] {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.tv-col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.tv-col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.tv-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.tv-col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.tv-col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.tv-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.tv-col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.tv-col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.tv-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.tv-col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.tv-col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.tv-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.tv-detail-customer-name {
  font: 1rem 'Circular Std', sans-serif;
  color: #a9a9a9; }

.rate-detail-box {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: auto;
  font: 1rem 'Circular Std', sans-serif;
  background-color: white; }
  .rate-detail-box > span {
    margin: 5px 0px;
    font: 0.875rem 'Circular Std', sans-serif; }

.disabled {
  color: darkgrey; }
  .disabled:after {
    color: darkgrey !important; }
  .disabled:before {
    color: darkgrey !important; }

.text-box-content {
  min-height: 70px; }

input,
select {
  background-color: #fff !important; }

@supports (display: grid) {
  .weekend-background {
    background-color: #f1f4ff !important; } }

.primary-color {
  color: #873bff; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes zoomIn {
  0% {
    transform: scale(0.9); }
  to {
    transform: scale(1); } }

@keyframes slide-in {
  100% {
    transform: translateX(0%); } }

.tv-buttons__normal, .tk-calendar__button {
  border: 0;
  background: transparent; }

.tv-buttons__element {
  padding: 11px 10px 9px;
  font-family: "Circular Std", "Circular Std";
  border-radius: 5px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none; }
  .tv-buttons__element:hover {
    text-decoration: none; }
  .tv-buttons__element--primary {
    color: #fff;
    background: #873bff;
    border: 1px solid #873bff; }
    .tv-buttons__element--primary.tv-buttons__element--disable {
      border: 1px solid #969696; }
  .tv-buttons__element--secondary {
    color: #fff;
    background: #873bff; }
  .tv-buttons__element--line-default {
    border: 1px solid #e0e0e0;
    color: #fff; }
  .tv-buttons__element--line-primary {
    color: #252525;
    border: 1px solid #e0e0e0; }
  .tv-buttons__element--line-secondary {
    color: #313131;
    border: 1px solid #323232; }
  .tv-buttons__element--disable {
    cursor: context-menu;
    color: #fff;
    background: #969696; }
  .tv-buttons__element--text {
    color: #873bff; }
  .tv-buttons__element--icon {
    padding: 6px 4px 2px;
    border-radius: 100%;
    font-size: 12px; }

.tv-buttons__type {
  background: transparent;
  border: 0;
  padding: 0; }

.tv-filter-list__element {
  position: relative;
  font-size: 0.875rem; }

.tv-filter-list__user-name {
  padding-left: 20px;
  color: #873bff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  /* width: -ms-max-content;*/ }
  .tv-filter-list__user-name.ie-fix {
    white-space: nowrap;
    width: auto; }

.tv-filter-list__filter-count-badge {
  padding: 0px 5px;
  color: white;
  background-color: #7e7676;
  border-radius: 25px;
  width: 5px; }

.tv-filter-list__button {
  font-family: "Circular Std", "Circular Std";
  color: #313131;
  text-transform: uppercase; }
  .tv-filter-list__button:before {
    content: '\e900';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #c5c5c5;
    margin-right: 9px; }
  .tv-filter-list__button:after {
    margin-left: 8px;
    content: '\e933';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #313131;
    font-size: 0.8rem; }
  .tv-filter-list__button:hover {
    text-decoration: none; }

.tv-filter-list__menu {
  font-family: "Circular Std", "Circular Std";
  position: absolute;
  margin: 12px 0 0 0;
  padding: 0;
  width: max-content; }
  .tv-filter-list__menu-item {
    border-bottom: 2px solid #f3f5f7;
    list-style: none; }
    .tv-filter-list__menu-item:last-child {
      border-bottom: 0; }
    .tv-filter-list__menu-item button {
      font-family: "Circular Std", "Circular Std";
      color: #313131;
      padding: 18px 24px 15px 14px;
      width: 100%;
      text-align: left; }

.tv-avatar-list__menu {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 2px 1px 4px 0 rgba(158, 158, 158, 0.522);
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  right: -8px;
  min-width: 300px;
  z-index: 100;
  text-align: left;
  padding-bottom: 16px; }
  .tv-avatar-list__menu-availability-btn {
    font: 300 1rem/1.4375rem "Circular Std", "Circular Std";
    padding: 8px 20px;
    margin-top: 16px; }
  .tv-avatar-list__menu-item {
    display: block;
    color: #873bff;
    display: block;
    padding: 8px 16px 8px 16px;
    font: 500 16px/20px "Circular Std", "Circular Std"; }
    .tv-avatar-list__menu-item.active {
      background-color: #eeeeee; }
    .tv-avatar-list__menu-item:hover {
      text-decoration: none;
      background-color: #eeeeee;
      color: #873bff; }
    .tv-avatar-list__menu-item-icon {
      margin-right: 10px;
      font-size: 0.9375rem; }
    .tv-avatar-list__menu-item--name {
      padding: 16px;
      font: 500 16px/20px "Circular Std", "Circular Std";
      border-bottom: 1px solid #e0e0e0; }
      .tv-avatar-list__menu-item--name.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--customer-name {
      padding: 16px;
      font: 500 16px/20px "Circular Std", "Circular Std";
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 8px;
      color: #252525; }
      .tv-avatar-list__menu-item--customer-name.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--customer-number {
      font: 0.875rem/1.25rem "Circular Std", "Circular Std";
      color: #a9a9a9;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #e5e5e5; }
      .tv-avatar-list__menu-item--customer-number.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--id {
      color: #873bff;
      padding-top: 0px;
      padding-bottom: 17px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 0.875rem; }
      .tv-avatar-list__menu-item--id.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--profile {
      padding: 16px 16px 0 16px;
      font: 500 16px/20px "Circular Std", "Circular Std"; }
    .tv-avatar-list__menu-item--logout {
      padding: 8px 16px 8px 16px;
      font: 500 16px/20px "Circular Std", "Circular Std"; }
    .tv-avatar-list__menu-item--ondemand.active {
      background-color: transparent; }

.tx-input-text {
  background-color: transparent;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ededed;
  padding: 10px 0px;
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  color: #313131; }
  .tx-input-text:focus {
    border-bottom: 1px solid #6478e6; }
  .tx-input-text.tv-form__control--error {
    border-bottom: 1px solid red; }
  .tx-input-text--inactive {
    color: #a9a9a9;
    -webkit-text-fill-color: #a9a9a9;
    -webkit-opacity: 1; }
  .tx-input-text--border {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 13px 20px; }
    .tx-input-text--border:focus {
      border-color: #873bff; }

.tv-toggle-button {
  display: inline-block;
  min-width: 145px;
  height: 49px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #6478e6;
  font-family: "Circular Std", "Circular Std";
  font-size: 14px;
  line-height: 49px;
  text-align: center;
  color: white;
  cursor: pointer;
  outline: none; }
  .tv-toggle-button--phone:before {
    content: '\e91a';
    margin-right: 15px;
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'; }
  .tv-toggle-button--onsite:before {
    content: '\e910';
    margin-right: 15px;
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'; }
  .tv-toggle-button--unselected {
    border-radius: 5px;
    border: 1px solid #6478e6;
    background-color: white;
    color: #313131; }
    .tv-toggle-button--unselected.tv-toggle-button--phone:before {
      color: #c5c5c5; }
    .tv-toggle-button--unselected.tv-toggle-button--onsite:before {
      color: #c5c5c5; }

.tv-date-picker {
  height: 52px;
  width: 188px;
  padding: 16px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  font-family: 'PT Serif';
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  cursor: pointer; }
  .tv-date-picker--inactive {
    color: #a9a9a9; }
  .tv-date-picker::after {
    content: '\e933';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 10px;
    color: #c5c5c5;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: 20px;
    margin-top: 14px; }

.tv-attachment {
  width: 130px;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer; }
  .tv-attachment__header {
    height: 80px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tv-attachment__header--doc, .tv-attachment__header--docx {
      background-color: #6478e6; }
      .tv-attachment__header--doc::after, .tv-attachment__header--docx::after {
        content: '\e93a';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 38px;
        color: #fff; }
    .tv-attachment__header--pdf {
      background-color: #ff9ba0; }
      .tv-attachment__header--pdf::after {
        content: '\e93b';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
    .tv-attachment__header--image {
      background-color: #454c98; }
      .tv-attachment__header--image::after {
        content: '\e940';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
    .tv-attachment__header--other {
      background-color: #454c98; }
      .tv-attachment__header--other::after {
        content: '\e930';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
  .tv-attachment__details {
    height: 40px;
    border: 1px solid #eaeaea;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #f8f8f8;
    color: #873bff;
    font: 0.625rem/2.5rem "Circular Std", "Circular Std";
    text-align: center; }
    .tv-attachment__details::before {
      content: '\e93c';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 10px;
      color: #322978;
      margin-right: 8px; }

.tv-file {
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent; }
  .tv-file__details {
    background-color: transparent;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    color: #873bff;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
    .tv-file__details::before {
      content: '\e91e';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 25px;
      color: #873bff;
      margin-right: 8px; }
    .tv-file__details-text {
      text-decoration: underline; }

.filter-title {
  margin-top: 27px !important;
  font-size: 0.875rem !important;
  font-weight: bold; }

.tv-filter-container__menu {
  background-color: #fff;
  box-shadow: 3px -5px 16px 0 rgba(159, 159, 159, 0.52);
  position: absolute;
  display: flex;
  font-size: 0.875rem;
  flex-direction: column;
  top: 38px;
  left: -8px;
  margin-top: 12px;
  padding-left: 20px;
  min-width: 250px;
  z-index: 100;
  font-family: "Circular Std", "Circular Std"; }
  .tv-filter-container__menu:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -16px;
    margin-left: 19px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #fff transparent; }

.tv-filter-container__action-container {
  display: flex;
  flex-direction: row; }

.tv-filter-container__sub-menu {
  min-width: max-content;
  width: 200px;
  margin-left: 5px;
  font-size: 0.875rem; }
  .tv-filter-container__sub-menu > span {
    text-transform: uppercase; }
  .tv-filter-container__sub-menu > span > h4 {
    margin-block-end: 0em !important; }
  .tv-filter-container__sub-menu > ul {
    padding-inline-start: 0px !important; }

.tv-filter-container__active-filters {
  width: 100%;
  float: left;
  padding-left: 10px; }
  .tv-filter-container__active-filters > button {
    border-radius: 4px;
    position: relative;
    border: 1px solid #807a7a;
    padding: 6px 30px 6px 10px;
    text-transform: uppercase;
    margin: 10px 10px 10px 0px;
    color: white;
    background: rgba(135, 127, 127, 0.823);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    user-select: none;
    transition: all 0.3s; }
    .tv-filter-container__active-filters > button:after {
      content: 'x';
      position: absolute;
      color: #ffff;
      top: 5px;
      right: 6px; }
    .tv-filter-container__active-filters > button:hover {
      transform: scale(1.03);
      color: #000000fa;
      background: rgba(0, 0, 0, 0.13);
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15); }
    .tv-filter-container__active-filters > button:active {
      transform: scale(0.96); }
      .tv-filter-container__active-filters > button:active .badge {
        transform: scale(1.2); }

.tv-single-assign-form__hr {
  border-top: 1px solid #969696; }

.tv-single-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-single-assign-form__container {
  background: #fff; }
  .tv-single-assign-form__container--order {
    margin: 0 auto 24px auto; }
    .tv-single-assign-form__container--order .tv-single-assign-form__control {
      color: #313131; }
    .tv-single-assign-form__container--order .tv-single-assign-form__panel {
      padding-bottom: 30px; }
  .tv-single-assign-form__container .tv-row {
    margin-right: -12px;
    margin-left: -12px; }
    .tv-single-assign-form__container .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px; }
  .tv-single-assign-form__container .tv-form__error {
    margin: 4px 0 3px;
    min-height: 1px;
    display: block; }
  .tv-single-assign-form__container .tv-form__control--error {
    margin-bottom: 0;
    border-color: red; }

.tv-single-assign-form__action-container {
  margin: 0 auto 32px auto;
  justify-content: flex-end; }
  .tv-single-assign-form__action-container button {
    box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
    margin-left: 24px;
    font: 1rem "Circular Std", "Circular Std";
    padding: 13px 16px 12px;
    font-weight: 300; }

.tv-single-assign-form__panel {
  padding: 10px 48px; }
  .tv-single-assign-form__panel--secondary {
    background: #f8f8f9;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 48px 20px; }

.tv-single-assign-form__sub-title {
  color: #873bff;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  margin: 24px 0 12px 0; }

.tv-single-assign-form__label, .tv-single-assign-form__input {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  line-height: 24px; }
  .tv-single-assign-form__label .tv-date-range-picker__container, .tv-single-assign-form__input .tv-date-range-picker__container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: block;
    width: 100%; }
    .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field {
      font: 0.875rem "Circular Std", "Circular Std";
      padding: 16px 20px;
      color: #a9a9a9;
      position: relative; }
      .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field:after, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field:after {
        font: 1rem;
        position: absolute;
        content: '\e929';
        font-family: 'transvoice-icon';
        top: 19px;
        right: 20px; }

.tv-single-assign-form__label {
  margin: 16px 0 5px 0; }

.tv-single-assign-form__input {
  position: relative; }
  .tv-single-assign-form__input-arrow:after {
    content: '\e933';
    position: absolute;
    font: 0.7rem 'transvoice-icon';
    right: 27px;
    top: 20px;
    color: #313131; }
  .tv-single-assign-form__input-sameabove {
    justify-content: flex-end;
    align-self: center;
    margin: 15px 0 0 0; }
    .tv-single-assign-form__input-sameabove .tv-toggleSwitch__wrapper {
      margin-left: 10px; }
  .tv-single-assign-form__input--my-home {
    margin-top: 15px; }

.tv-single-assign-form__control {
  border: 1px solid #969696;
  border-radius: 4px;
  width: 100%;
  font: 0.875rem "Circular Std", "Circular Std";
  outline: none;
  color: #a9a9a9;
  margin-bottom: 8px; }

.tv-single-assign-form__dropdownlist {
  padding: 14px 20px;
  appearance: none; }

.tv-single-assign-form__textfield {
  padding: 14px 20px; }
  .tv-single-assign-form__textfield-disabled {
    background-color: #f8f8f9; }

.tv-single-assign-form__textarea {
  height: 120px; }

.tv-single-assign-form__file-container {
  margin: 0;
  padding: 0;
  flex-flow: row wrap; }

.tv-single-assign-form__file-element {
  list-style: none;
  margin: 0 8px 0 0;
  width: calc((100% - 24px) / 4); }
  .tv-single-assign-form__file-element:nth-child(4n + 0) {
    margin-right: 0; }

.tv-single-assign-form__file-name {
  border: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  background: #f8f8f8;
  font: 0.625rem "Circular Std", "Circular Std";
  color: #873bff;
  bottom: 0;
  position: absolute;
  display: flex;
  width: 100%;
  padding: 10px 0; }
  .tv-single-assign-form__file-name:before {
    content: '\e93c';
    color: #873bff;
    font: 0.75rem 'transvoice-icon';
    margin: 0 8px; }

.tv-single-assign-form__file-item {
  min-height: 120px;
  border-radius: 5px;
  position: relative;
  justify-content: center;
  display: flex; }
  .tv-single-assign-form__file-item:before {
    color: #fff;
    font: 2.6rem 'transvoice-icon';
    margin-top: 20px; }
  .tv-single-assign-form__file-item--pdf {
    background: #ff9ba0; }
    .tv-single-assign-form__file-item--pdf:before {
      content: '\e93b'; }
  .tv-single-assign-form__file-item--doc, .tv-single-assign-form__file-item--docx, .tv-single-assign-form__file-item--png, .tv-single-assign-form__file-item--jpg {
    background: #6478e6; }
    .tv-single-assign-form__file-item--doc:before, .tv-single-assign-form__file-item--docx:before, .tv-single-assign-form__file-item--png:before, .tv-single-assign-form__file-item--jpg:before {
      content: '\e93a'; }
  .tv-single-assign-form__file-item--other {
    background: #6478e6; }
    .tv-single-assign-form__file-item--other:before {
      content: ''; }
  .tv-single-assign-form__file-item--dragdrop {
    background: #fff;
    border: 1px dashed #cbcbcb;
    outline: none;
    font: 0.75rem "Circular Std", "Circular Std";
    font-weight: 300;
    text-transform: uppercase;
    color: #c5c5c5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    height: 120px;
    cursor: pointer; }
    .tv-single-assign-form__file-item--dragdrop:before {
      content: '\e93d';
      color: #873bff;
      font: 1.4rem 'transvoice-icon';
      margin-bottom: 4px; }

.tv-single-assign-form__action-container .tv-single-assign-form__button-save {
  border-color: #fff;
  background: #fff; }

.tv-single-assign-form__button-addSession {
  border: 1px dashed #873bff;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  cursor: pointer;
  color: #873bff;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  font-weight: bold;
  text-align: center;
  outline: none;
  padding: 8px 0 10px 0;
  margin-top: 16px; }

.tv-single-assign-form__button-removeSession {
  background: #873bff;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  outline: none; }
  .tv-single-assign-form__button-removeSession:before {
    content: '\e926';
    color: #fff;
    font-family: 'transvoice-icon'; }

.tv-single-assign-form__button-removeFile {
  color: #873bff;
  border: 0;
  background: transparent;
  display: flex;
  outline: none;
  font: 0.875rem "Circular Std", "Circular Std";
  cursor: pointer;
  margin: 10px 0; }
  .tv-single-assign-form__button-removeFile:before {
    background: #873bff;
    content: '\e926';
    color: #fff;
    font: 0.45rem 'transvoice-icon';
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 4px;
    display: inline-block;
    margin: 3px 7px 0 0; }

.tv-single-assign-form__preview-text {
  font: bold 1rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-single-assign-form__preview-last {
  margin-bottom: 24px; }

.tv-single-assign-form__back-corner {
  position: absolute;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-single-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-multiple-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-multiple-assign-form__panel-left {
  margin-right: 37px; }

.tv-multiple-assign-form__panel-tab {
  width: 323px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 20px 24px;
  margin-bottom: 18px;
  border: 1px solid #fff; }
  .tv-multiple-assign-form__panel-tab--error {
    border: 1px solid #873bff; }
  .tv-multiple-assign-form__panel-tab--selected {
    border: 1px solid #873bff; }
    .tv-multiple-assign-form__panel-tab--selected:after, .tv-multiple-assign-form__panel-tab--selected:before {
      left: 100%;
      top: 32px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .tv-multiple-assign-form__panel-tab--selected:after {
      border-left-color: #fff;
      border-width: 6px; }
    .tv-multiple-assign-form__panel-tab--selected:before {
      border-left-color: #873bff;
      border-width: 8px;
      top: 30px; }
    .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error {
      border: 1px solid #873bff; }
      .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error:before {
        border-left-color: #873bff; }

.tv-multiple-assign-form__panel-title {
  color: #313131;
  font: 1.125rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  border-bottom: 1px solid #e8ecef;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0; }

.tv-multiple-assign-form__attributes {
  margin: 0;
  padding: 0; }
  .tv-multiple-assign-form__attributes li {
    list-style: none;
    margin: 5px 0;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
    .tv-multiple-assign-form__attributes li.level::before {
      content: '\e927';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.category::before {
      content: '\e92c';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.datetime::before {
      content: '\e929';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }

.tv-multiple-assign-form__button-add-more {
  border: 1px dashed #873bff;
  box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
  border-radius: 5px;
  color: #873bff;
  background: #fff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  width: 100%;
  margin-top: 6px;
  padding: 10px 0;
  cursor: pointer; }

.tv-multiple-assign-form__button-delete {
  color: #873bff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  background: transparent;
  border: 0;
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .tv-multiple-assign-form__button-delete:before {
    content: '\e926';
    color: #873bff;
    font: 0.5rem 'transvoice-icon';
    height: 8px;
    width: 8px;
    display: inline-block;
    margin: 0 7px 0 0; }

.tv-multiple-assign-form__back-corner {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-multiple-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-form__checkbox-container {
  position: relative;
  display: flex;
  align-items: center; }
  .tv-form__checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0; }
    .tv-form__checkbox-container input:checked ~ .tv-form__checkbox-checkmark {
      background-color: #873bff;
      border-color: #fff; }
      .tv-form__checkbox-container input:checked ~ .tv-form__checkbox-checkmark:after {
        content: '\e939';
        font: 7px 'transvoice-icon';
        color: #fff;
        position: absolute;
        top: 4px;
        left: 3px; }
  .tv-form__checkbox-container--small-text {
    font-size: 0.75rem; }

.tv-form__checkbox-circular-container {
  font-size: 14px !important;
  display: flex;
  align-items: inherit;
  margin: 0px 8px 8px 8px; }
  .tv-form__checkbox-circular-container .tv-form__checkbox-checkmark {
    border-radius: 10px !important;
    margin-right: 8px; }

.tv-form__checkbox-checkmark {
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  margin-right: 10px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding-right: 15px; }

.tv-form__error, .tv-form__warning {
  font: 12px "Circular Std", "Circular Std";
  font-weight: 300;
  color: red;
  display: inline-block;
  min-width: 20px;
  line-height: 19px; }

.tv-form__warning {
  color: #6478e6;
  margin-top: 8px; }

.tv-form__date-field {
  background-color: #fff; }
  .tv-form__date-field:after {
    content: '\e929';
    font-family: 'transvoice-icon'; }

.k-option {
  cursor: pointer;
  background-color: #f7f7f7; }

.k-option .k-item.k-state-selected {
  color: #ff4081;
  background-color: #f7f7f7; }

.k-option > .k-item {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer; }

.tv-left-pannel__wrapper {
  flex: 1;
  max-width: 250px;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5);
  z-index: 10; }

.tv-left-pannel__logo {
  font-family: "Circular Std", "Circular Std";
  letter-spacing: -1px;
  display: flex;
  justify-content: center;
  cursor: pointer; }
  .tv-left-pannel__logo a {
    text-decoration: none; }
  .tv-left-pannel__logo img {
    width: 175px; }

.tv-left-pannel__sidebar a {
  text-decoration: none; }

.tv-left-pannel__sidebar .tv-icon-fixed-width {
  display: inline-block;
  width: 24px;
  text-align: center; }

.tv-left-pannel__sidebar-item {
  display: flex;
  padding: 20px 10px 20px 20px;
  cursor: pointer;
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__sidebar-item:hover, .tv-left-pannel__sidebar-item.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__sidebar-item:hover i:before, .tv-left-pannel__sidebar-item.active i:before {
      color: #873bff; }

.tv-left-pannel__header-menu {
  display: flex;
  cursor: pointer;
  color: #313131;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__header-menu .k-state-selected {
    font-weight: bold; }
    .tv-left-pannel__header-menu .k-state-selected .icon-schedule:before {
      color: #873bff !important; }
  .tv-left-pannel__header-menu:hover, .tv-left-pannel__header-menu.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__header-menu:hover .icon-schedule:before, .tv-left-pannel__header-menu.active .icon-schedule:before {
      color: #873bff; }

.tv-left-pannel__header-menu {
  display: flex;
  cursor: pointer;
  color: #313131;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__header-menu .k-state-selected {
    font-weight: bold; }
    .tv-left-pannel__header-menu .k-state-selected .icon-schedule:before {
      color: #873bff !important; }
  .tv-left-pannel__header-menu:hover, .tv-left-pannel__header-menu.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__header-menu:hover .icon-schedule:before, .tv-left-pannel__header-menu.active .icon-schedule:before {
      color: #873bff; }

.tv-left-pannel__sidebar-icon {
  font-size: 21px;
  margin-right: 10px; }

.tv-right-pannel-wrapper {
  flex: 4.76;
  min-height: 100vh;
  position: relative;
  background-color: #f7f9ff; }
  .tv-right-pannel-wrapper .tv-right-status {
    width: 100%;
    height: 72px;
    background-color: #d2d5db; }
    .tv-right-pannel-wrapper .tv-right-status .tv-avatar {
      display: inline;
      position: relative;
      cursor: pointer; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar__img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification {
        display: inline;
        margin-left: 30px;
        position: relative;
        cursor: pointer; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification__icon {
          vertical-align: middle;
          font-size: 1.4rem;
          color: #c5c5c5; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification__circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #873bff;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: 10px; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search {
        margin-left: auto; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline {
          display: flex;
          align-items: center; }
          .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline i {
            margin-right: 15px;
            font-size: 20px;
            cursor: pointer; }
          .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline input {
            width: 16rem;
            border: none;
            font-family: 'PT Serif';
            font-size: 1rem;
            color: #313131; }
  .tv-right-pannel-wrapper .tv-right-content {
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    margin-left: auto;
    margin-right: auto; }

.tv-bg-login-page {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 768px) {
  .tv-login-page__wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh; } }

.tv-login-page__form-container {
  display: -webkit-flex;
  display: flex;
  margin: auto;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center; }

.tv-login-page__right-wrapper {
  background-color: #fff;
  flex: 1.4;
  justify-content: center;
  align-items: center; }
  .tv-login-page__right-wrapper .tab-panel-content {
    padding: 52px;
    max-height: 100vh;
    overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    .tv-login-page__right-wrapper {
      flex: none;
      margin-top: 25px; } }

.tv-login-page__right-footer-wrapper {
  position: absolute;
  font-family: "Circular Std", "Circular Std";
  bottom: 0;
  margin: auto auto 26px auto; }
  @media screen and (max-width: 768px) {
    .tv-login-page__right-footer-wrapper {
      position: relative;
      margin: 26px auto 26px auto; } }

.tv-login-page__right-footer-text {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-login-page__right-footer-text span {
    margin-left: 20px;
    margin-right: 20px; }

.tv-login__wrapper {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 502px; }
  @media screen and (max-width: 768px) {
    .tv-login__wrapper {
      box-shadow: none;
      border: none; } }

.tv-login__title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 40px; }

.tv-login__description {
  padding: 10px 30px 10px 30px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  text-align: center;
  color: #313131; }

.tv-login__form-wrapper {
  width: 100%;
  padding: 36px 56px 56px 56px;
  font-family: "Circular Std", "Circular Std";
  margin-top: 16px; }
  .tv-login__form-wrapper .tv-form__control {
    border: 1px solid #e0e0e0;
    height: 48px;
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    font-weight: 400;
    background-color: #ffffff !important; }
  @media screen and (max-width: 768px) {
    .tv-login__form-wrapper {
      margin: 16px;
      width: calc(100vw - 32px);
      padding: 0 16px;
      box-shadow: none; } }

.tv-login__form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  margin-bottom: 8px; }

.tv-login__form-email-input {
  width: 100%; }

.tv-login__form-interpreter-id-label {
  margin-top: 15px; }

.tv-login__form-interpreterId-input {
  width: 100%; }

.tv-login__form-submit.tv-buttons__element {
  background: #873bff;
  border-color: #873bff;
  width: 100%;
  margin-top: 26px;
  padding: 13px 0px 13px 0px;
  font-weight: 300;
  line-height: 1.4375rem; }

.tv-workassignment__wrapper {
  margin: 30px 20px 0 16px; }

.tv-workassignment__status-wrapper {
  margin-left: 15px;
  margin-right: 10px; }

.tv-workassignment__main-title {
  font-family: "Circular Std", "Circular Std";
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px; }

.tv-workassignment__title, .tv-youravailability__title {
  color: #353535;
  font-weight: 500;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  margin: 0 0 20px; }

.tv-workassignment__control-panel {
  margin: 0 0 20px; }
  .tv-workassignment__control-panel.h-pdl--30 {
    padding-left: 30px; }
  .tv-workassignment__control-panel .h-fs--16 {
    font-size: 16px; }

.tv-workassignment__date_picker {
  margin: 15px 0 4px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-workassignment__date_picker-title {
    font-size: 16px;
    padding: 6px 0; }
  .tv-workassignment__date_picker-input {
    padding: 3px 12px 0 15px;
    background: #f4f6f9;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    height: 47px;
    width: 100%;
    font: 400 16px/20px "Circular Std", "Circular Std";
    text-transform: capitalize; }
  .tv-workassignment__date_picker-icon {
    position: absolute;
    top: 45px;
    left: 288px;
    transform: rotate(270deg); }

.tv-workassignment__list-item {
  cursor: pointer;
  margin: 0;
  padding: 8px;
  height: 47px;
  font: 400 12px/15px "Circular Std", sans-serif;
  z-index: 8;
  border-radius: 0;
  background: #f1f3f4;
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center; }
  .tv-workassignment__list-item.selected {
    background: #873bff !important; }
    .tv-workassignment__list-item.selected .tv-reportquality__item-text {
      color: #fff !important; }
    .tv-workassignment__list-item.selected .icon-hearing:before {
      background-color: #ffffff; }
  .tv-workassignment__list-item-child {
    margin: 0 13px;
    box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
  .tv-workassignment__list-item-label {
    display: none; }
  .tv-workassignment__list-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .tv-workassignment__list-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .tv-workassignment__list-item:nth-child(even) {
    background: #f1f3f4; }
  .tv-workassignment__list-item:nth-child(odd) {
    background: #f7f9ff; }

.tv-workassignment__item-status {
  width: 21.6%;
  align-items: center;
  justify-content: center; }

.tv-workassignment__item-icon {
  padding: 15px 40px; }

.tv-workassignment__item-order {
  width: 30%;
  margin-right: 10px; }

.tv-workassignment__item-name {
  width: 29.1%; }

.tv-workassignment__item-category {
  width: 16.7%; }

.tv-workassignment__item-datetime {
  width: 27.6%; }

.tv-workassignment__item-type {
  width: 12%;
  margin-right: 10px; }

.tv-workassignment__item-more_button {
  outline: none;
  border: none;
  background: none;
  text-transform: uppercase;
  font-size: 14px; }
  .tv-workassignment__item-more_button:after {
    content: "\e931";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #c5c5c5;
    margin-left: 4px;
    font-size: 10px;
    color: #000; }

.tv-workassignment__item-menu {
  width: 7%;
  align-items: center;
  justify-content: center; }
  .tv-workassignment__item-menu-button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 1.5rem; }

.tv-workassignment__item-link-button {
  color: #873bff;
  font-weight: 500;
  font-size: 14px; }
  .selected .tv-workassignment__item-link-button {
    color: #fff; }

.tv-workassignment__item-label {
  font-size: 0.975rem;
  color: #000;
  margin: 0 0 11px;
  font-family: "Circular Std", "Circular Std"; }

.tv-workassignment__item-text {
  color: #252525;
  font: 400 13px/18px "Circular Std", "Circular Std"; }
  .tv-workassignment__item-text.text-max-lenght {
    max-width: calc(70vw / 7);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .tv-workassignment__item-text .icon {
    text-align: center;
    font-size: 16px; }
    .tv-workassignment__item-text .icon:before {
      color: #74737b; }
  .tv-workassignment__item-text-date {
    font: 400 13px/18px "Circular Std", "Circular Std"; }
  @supports (-webkit-background-clip: text) {
    .tv-workassignment__item-text .stars {
      --percent: var(--rating);
      line-height: 8px;
      display: inline-block;
      font-family: Times;
      -webkit-text-stroke: 1px #ffb074; }
      .tv-workassignment__item-text .stars::before {
        content: "★★★★★";
        font-size: 15px;
        letter-spacing: 3px;
        background: linear-gradient(90deg, #ff9b4e var(--percent), transparent var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } }
  .tv-workassignment__item-text .stars {
    color: #ffb074;
    font-family: Times;
    letter-spacing: 3px;
    font-size: 15px; }
  .tv-workassignment__item-text-available-until {
    font-weight: bold;
    color: #873bff; }
  .selected .tv-workassignment__item-text {
    color: #fff; }
    .selected .tv-workassignment__item-text .icon {
      text-align: center;
      font-size: 16px; }
      .selected .tv-workassignment__item-text .icon:before {
        color: #fff; }

.tv-workassignment__status {
  font: 12px/16px "Circular Std", "Circular Std";
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 6px 8px;
  letter-spacing: 0.5px; }
  .tv-workassignment__status--available {
    background: #ff9b4e; }
  .tv-workassignment__status--accepted {
    background: #6478e6; }
  .tv-workassignment__status--rejected {
    background: #e94242; }
  .tv-workassignment__status--fullfilled {
    background: #18ad79;
    color: #fff; }
  .tv-workassignment__status--cancelled {
    background: #74737b; }
  .tv-workassignment__status--losted {
    background: #696969; }
  .tv-workassignment__status--traveling, .tv-workassignment__status--working {
    background: #fbbcdb;
    color: black; }
  .tv-workassignment__status--done {
    background: #ffa500;
    color: black; }
  .tv-workassignment__status--submitted {
    background: #008000; }
  .tv-workassignment__status--ordered {
    background: #6478e6; }
  .tv-workassignment__status--notPerformed {
    background: #ff9b4e;
    text-wrap: nowrap; }
  .tv-workassignment__status--interrupted {
    background: #74737b; }

.tv-workassignment__notification {
  background: #f1f3f4;
  border-radius: 4px;
  padding: 15px 8px;
  margin: 8px 0;
  display: flex;
  justify-content: space-between; }
  .tv-workassignment__notification__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #252525;
    margin-bottom: 7px; }
  .tv-workassignment__notification__more {
    font-size: 12px;
    text-decoration-line: underline;
    color: #677adf; }
  .tv-workassignment__notification__new-label {
    background: #e94242;
    border-radius: 30px;
    color: #ffffff;
    font-size: 8px;
    height: 16px;
    width: 35px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 4px 8px;
    font-weight: 500;
    margin-top: 13px; }

.tv-workassignment__detail-wrapper {
  font-family: "Circular Std", "Circular Std";
  margin-top: 8px; }

.tv-workassignment__detail-article-icon {
  font-size: 20px; }
  .tv-workassignment__detail-article-icon--available {
    color: #ff9b4e !important; }
  .tv-workassignment__detail-article-icon--accepted {
    color: #6478e6 !important; }
  .tv-workassignment__detail-article-icon--rejected {
    color: #e94242 !important; }
  .tv-workassignment__detail-article-icon--fullfilled {
    color: #18ad79 !important; }
  .tv-workassignment__detail-article-icon--cancelled {
    color: #74737b !important; }
  .tv-workassignment__detail-article-icon--ordered {
    color: #6478e6; }
  .tv-workassignment__detail-article-icon--notPerformed {
    color: #ff9b4e;
    text-wrap: nowrap; }
  .tv-workassignment__detail-article-icon--interrupted {
    color: #74737b; }

.tv-workassignment__detail-articlename {
  font-size: 0.875rem;
  margin: 5px 10px 5px 0; }

.tv-workassignment__detail-status {
  font-size: 1rem;
  font-weight: 400;
  color: #252525;
  align-items: center;
  justify-content: space-between; }

.tv-workassignment__detail-orderno {
  color: #454c98; }

.tv-workassignment__detail-name {
  font: 1.5rem/2.1875rem "Circular Std", "Circular Std";
  font-weight: 300;
  color: #a9a9a9;
  color: #353535;
  margin: 0 10px 0 0; }

.tv-workassignment__detail-datetime {
  font-size: 0.875rem;
  color: #873bff;
  margin: 6px 0 24px 32px; }
  .tv-workassignment__detail-datetime .icon-calendar {
    margin-right: 14px; }

.tv-workassignment__detail-title {
  font: 1rem "Circular Std", "Circular Std";
  color: #313131;
  background-color: #f9f9f9;
  padding: 14px 0 13px 32px; }

.tv-workassignment__detail-description {
  margin: 16px 32px 0;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-workassignment__detail-buttons {
  margin: 15px 0 24px;
  font-weight: 600;
  justify-content: space-between;
  flex-direction: row-reverse; }
  .tv-workassignment__detail-buttons .tv-buttons__element {
    padding: 13px 0 12px;
    width: 47.04%;
    font-weight: 500;
    font-size: 16px; }
    .tv-workassignment__detail-buttons .tv-buttons__element-link {
      width: 47.04%; }
      .tv-workassignment__detail-buttons .tv-buttons__element-link button {
        width: 100%; }
    .tv-workassignment__detail-buttons .tv-buttons__element-primary {
      background-color: #873bff;
      color: #fff; }
    .tv-workassignment__detail-buttons .tv-buttons__element--report {
      padding: 13px 24px 12px;
      width: unset; }

.tv-workassignment__detail-links {
  display: flex;
  flex-direction: row;
  font-family: "Circular Std", "Circular Std";
  margin-right: 10px; }
  @supports (display: flex) {
    .tv-workassignment__detail-links {
      align-items: flex-end;
      justify-content: flex-end; } }
  .tv-workassignment__detail-links-item {
    display: flex;
    min-width: 70px;
    letter-spacing: 0.5px;
    font-size: 14px !important;
    color: #873bff;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer; }
    .tv-workassignment__detail-links-item:hover {
      opacity: 1;
      color: #873bff;
      text-decoration: underline #873bff 2px; }
    .tv-workassignment__detail-links-item-button {
      font-weight: 600;
      color: #000;
      text-decoration: none; }
      .tv-workassignment__detail-links-item-button:hover {
        color: #000;
        text-decoration: underline #873bff 2px; }

.tv-workassignment__detail-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
  padding: 0 12px; }

.tv-workassignment__detail-sub-status {
  font: 300 12px/15px "Circular Std", "Circular Std";
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 16px;
  letter-spacing: 0.4px;
  text-align: center; }
  .tv-workassignment__detail-sub-status--searching-for-interpreter {
    background-color: rgba(255, 155, 78, 0.32); }
  .tv-workassignment__detail-sub-status--interpreter-accepted {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--order-in-progress {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--assigning-interpreter {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--awaiting-time-report {
    background-color: rgba(24, 173, 121, 0.32); }
  .tv-workassignment__detail-sub-status--time-report-received {
    background-color: rgba(24, 173, 121, 0.32); }
  .tv-workassignment__detail-sub-status--deviation-registered {
    background-color: #74737b;
    color: #fff; }
  .tv-workassignment__detail-sub-status--order-cancelled {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--order-rejected {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--unexpected-event-occured {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--unknown-status {
    background-color: #fff; }

.tv-workassignment__attach-title {
  padding: 14px 0 13px 32px;
  color: #a9a9a9;
  font-family: "PT Serif";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px; }

.tv-workassignment__attach-list {
  padding: 0 0 17px 32px; }
  .tv-workassignment__attach-list .tv-attachment {
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: transparent; }

.tv-workassignment__modal {
  top: 0 !important;
  position: absolute;
  z-index: 65; }
  .tv-workassignment__modal-header {
    font: 500 18px/20px "Circular Std", "Circular Std";
    text-align: left;
    padding: 18px 10px;
    height: 56px;
    color: #000;
    border-bottom: 1px solid #e0e0e0;
    letter-spacing: 0.2px; }
    .tv-workassignment__modal-header > .secondary-heading {
      font: 400 14px/18px "Circular Std", "Circular Std";
      color: #74737b; }
  .tv-workassignment__modal-subheader {
    font: 600 16px/18px "Circular Std", "Circular Std";
    padding: 10px 0 16px; }
  .tv-workassignment__modal-detail-container {
    margin-top: 12px; }
  .tv-workassignment__modal-container {
    overflow: hidden;
    margin-top: 5px;
    border-bottom: 1px solid #e0e0e0;
    font-family: "Circular Std", "Circular Std";
    text-align: left;
    margin-right: -10px;
    min-width: 605px;
    height: 30vh !important;
    min-height: 250px; }
  .tv-workassignment__modal-scroll-container {
    overflow: hidden;
    margin-top: 5px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-right: -10px;
    min-width: 605px; }
    .tv-workassignment__modal-scroll-container > div > div:nth-child(2) {
      display: none; }
  .tv-workassignment__modal .tv-modal__overlay {
    top: 0 !important;
    left: -10px !important;
    width: 100vw !important;
    height: var(--vh) !important; }
  .tv-workassignment__modal-demo .tv-modal__holder {
    width: 800px !important;
    max-width: 800px !important; }
  .tv-workassignment__modal .tv-modal__holder {
    border-radius: 4px;
    width: 620px;
    max-width: 620px;
    max-height: unset;
    top: 63px;
    background-color: #fff;
    overflow-x: hidden; }
    .tv-workassignment__modal .tv-modal__holder .icon-close::before {
      color: #313131; }
  .tv-workassignment__modal-info-label {
    font: 600 14px/18px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    margin-bottom: 8px; }
    .tv-workassignment__modal-info-label.labelled {
      color: #20175b; }
  .tv-workassignment__modal-info-content {
    font: 14px/18px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    white-space: nowrap;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px; }
    .tv-workassignment__modal-info-content.red-text {
      color: #873bff; }
    .tv-workassignment__modal-info-content.labelled {
      color: #20175b; }
  .tv-workassignment__modal-info-condition {
    font: 400 11px/14px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    margin-bottom: 16px; }
  .tv-workassignment__modal-status {
    font: 900 0.975rem "Circular Std", "Circular Std";
    margin-left: 0.5rem;
    padding: 10px 15px 0; }

.tv-workassignment__info-bank-id {
  width: 25px;
  height: 16px;
  background-image: url("/images/bankid.png");
  background-repeat: no-repeat;
  margin-right: 12px;
  margin-left: -5px;
  background-size: contain; }

.tv-workassignment__info-wrapper {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 300;
  overflow-y: scroll;
  scroll-behavior: smooth; }

.tv-workassignment__info-item {
  list-style: none;
  margin: 0 0 17px;
  font-size: 12px;
  color: #252525;
  display: flex; }
  .tv-workassignment__info-item.red-text {
    color: #873bff;
    font-weight: 500; }
  .tv-workassignment__info-item.labelled {
    background-color: #f4d0dd;
    padding: 8px 16px;
    border-radius: 8px; }
  .tv-workassignment__info-item i {
    color: #74737b;
    margin-right: 12px;
    font-size: 14px;
    min-width: 14px; }
    .tv-workassignment__info-item i .lock-image {
      width: 12px; }
  .tv-workassignment__info-item > span ~ a {
    margin-left: 5px; }
  .tv-workassignment__info-item a {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #873bff; }
  .tv-workassignment__info-item .tv-workassignment__item-text > .stars {
    font-size: 24px; }
    .tv-workassignment__info-item .tv-workassignment__item-text > .stars::before {
      font-size: 24px; }
  .tv-workassignment__info-item.icon-none-icon:before {
    content: "";
    margin-right: 30px; }
  .tv-workassignment__info-item .detail-tel-no {
    color: #637dec;
    text-decoration: underline; }

.tv-workassignment__info-agree {
  margin: 17px 32px 0;
  color: #313131;
  font: italic 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-workassignment__info-agree-terms {
    cursor: pointer;
    text-decoration: underline; }

.tv-workassignment__info-title {
  font: 700 16px/20px "Circular Std", "Circular Std";
  color: #535252;
  margin-bottom: 17px; }

.tv-workassignment__info-banner {
  display: flex;
  justify-content: center;
  background: #ff9ba04d;
  padding: 15px 5px;
  align-items: center;
  border-radius: 4px; }
  .tv-workassignment__info-banner__icon {
    font-size: 14px;
    min-width: 14px;
    color: #535252; }
  .tv-workassignment__info-banner__text {
    font: 600 12px/15px "Circular Std", "Circular Std";
    padding-left: 10px; }

.tv-workassignment__drawer-overlay {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 16; }

.tv-workassignment__drawer-holder {
  width: 468px;
  background: #fff;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 20;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  transform: translateX(100%); }
  .js-slide-in .tv-workassignment__drawer-holder {
    transform: translateX(0%); }

.tv-workassignment__drawer-button-wrapper {
  width: 100%;
  text-align: left;
  position: absolute;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 9px; }

.tv-workassignment__drawer-next {
  top: 12px;
  right: 40px;
  font-size: 13px;
  margin-left: 30px;
  display: flex;
  align-content: center; }
  .tv-workassignment__drawer-next:before {
    color: #313131;
    font-size: 1rem; }
  .tv-workassignment__drawer-next:after {
    padding-left: 4px; }
  .tv-workassignment__drawer-next:disabled {
    color: darkgrey; }

.tv-workassignment__drawer-previous {
  top: 12px;
  right: 140px;
  font-size: 13px; }
  .tv-workassignment__drawer-previous:before {
    color: #313131;
    font-size: 0.8rem;
    padding-right: 5px; }
  .tv-workassignment__drawer-previous:disabled {
    color: darkgrey; }

.tv-workassignment__drawer--next {
  top: 0;
  right: 0;
  font-size: 12px;
  padding-right: 8px;
  cursor: pointer;
  display: flex;
  align-content: center; }
  .tv-workassignment__drawer--next:after {
    padding-left: 5px;
    font-size: 1rem;
    position: absolute;
    top: -2px;
    right: 5px;
    line-height: 20px !important; }
  .tv-workassignment__drawer--next:disabled {
    color: darkgrey;
    cursor: not-allowed; }

.tv-workassignment__drawer--previous {
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 12px;
  padding-left: 8px;
  display: flex;
  align-content: center; }
  .tv-workassignment__drawer--previous:before {
    padding-right: 5px;
    font-size: 1rem;
    position: absolute;
    top: -2px;
    left: 5px;
    line-height: 18px !important; }
  .tv-workassignment__drawer--previous:disabled {
    color: darkgrey;
    cursor: not-allowed; }

.tv-workassignment__drawer-close {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer-close:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__drawer2-overlay2 {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5; }

.tv-workassignment__drawer2-holder2 {
  width: 560px;
  background: #fff;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 15;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  transform: translateX(100%); }
  .js-slide-in2 .tv-workassignment__drawer2-holder2 {
    transform: translateX(0%); }

.tv-workassignment__drawer2-close2 {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer2-close2:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__filter-date-range {
  position: relative; }
  .tv-workassignment__filter-date-range__content {
    position: absolute;
    box-shadow: 1px 1px 4px 1px #969696;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 150;
    border-radius: 4px;
    left: 15px; }
    .tv-workassignment__filter-date-range__content .calendar-small .rdrDateRangePickerWrapper {
      font-family: "Circular Std", "Circular Std" !important;
      border-radius: 4px;
      margin-bottom: 12px;
      border: none;
      box-shadow: none;
      width: 100%; }
    .tv-workassignment__filter-date-range__content .tv-buttons__element {
      width: 30%;
      margin: 0 auto 10px; }

@media (max-width: 768px) {
  .tv-workassignment__wrapper .filter-border {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 16px; }
  .tv-workassignment__item-icon {
    padding: 0 12px;
    height: 24px;
    order: 1; }
    .tv-workassignment__item-icon .icon {
      font-size: 24px !important; }
  .tv-workassignment__item-status {
    width: 30%;
    order: 4;
    max-width: unset;
    padding-right: 5px; }
  .tv-workassignment__item-order {
    width: 30%;
    order: 2;
    max-width: unset;
    padding: 5px; }
    .tv-workassignment__item-order .tv-workassignment__item-text {
      font-weight: 600 !important; }
    .tv-workassignment__item-order .label {
      display: none; }
  .tv-workassignment__item-name {
    width: 50%;
    order: 5;
    max-width: unset;
    margin-top: 10px;
    padding-right: 0;
    text-align: end; }
  .tv-workassignment__item-category {
    width: 30%;
    order: 3;
    max-width: unset;
    padding: 4px; }
    .tv-workassignment__item-category .label {
      display: none; }
    .tv-workassignment__item-category .tv-workassignment__item-text {
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .tv-workassignment__item-datetime {
    width: 50%;
    order: 5;
    max-width: unset;
    flex: 0 0 40%;
    margin-top: 10px; }
  .tv-workassignment__item-type {
    width: 12%;
    order: 6;
    max-width: unset; }
  .tv-workassignment__item-text {
    display: flex;
    font: 400 12px/15px "Circular Std", "Circular Std";
    margin-top: 17px; }
    .tv-workassignment__item-text:last-child {
      margin-bottom: 17px; }
    .tv-workassignment__item-text-label {
      font: 400 12px/15px "Circular Std", "Circular Std";
      margin: 0 8px 0 0; }
      .tv-workassignment__item-text-label i {
        color: #74737b; }
  .tv-workassignment__item .list-small-rating > label:before {
    font-size: 12px !important; }
  .tv-workassignment__list {
    padding-bottom: 70px;
    margin-right: auto;
    margin-bottom: 80px; }
    .tv-workassignment__list-item {
      height: 200px;
      border-radius: 4px;
      position: relative;
      border: 1px solid #e0e0e0;
      margin: 16px 0 !important;
      padding: 16px; }
      .tv-workassignment__list-item .tv-workassignment__detail-status {
        margin-top: 17px;
        font: 500 16px/20px "Circular Std", "Circular Std"; }
      .tv-workassignment__list-item.selected .tv-workassignment__detail-status {
        color: #fff !important; }
  .tv-workassignment__filter-date-range__content {
    position: fixed;
    left: 35px;
    border-radius: 4px; }
  .tv-workassignment__detail-links {
    display: flex;
    flex-direction: column; }
  .tv-workassignment__detail-footer {
    bottom: 10px; }
  .tk-assigment-list-filter-footer {
    position: fixed;
    z-index: 60;
    bottom: 0;
    padding: 14px 24px;
    left: 0;
    width: 100vw;
    background-color: #fff; } }

.list-small-rating > label {
  padding-right: 0 !important; }

.list-small-rating > label:before {
  font-size: 12px !important; }

.tv-single-assign-form__control {
  height: 48px !important;
  font: 400 16px/20px "Circular Std", "Circular Std"; }
  .tv-single-assign-form__control .k-input {
    font: 400 16px/20px "Circular Std", "Circular Std" !important;
    height: 100% !important; }

button:focus {
  outline: none; }

.tk-calendar__main-title {
  font: 15px/19px "Circular Std", "Circular Std";
  color: #74737b;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.2px;
  bottom: 10px;
  position: fixed; }

.tk-calendar__banner-container {
  position: relative;
  display: inline-block; }
  .tk-calendar__banner-container .banner {
    width: 320px;
    height: 209px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 2px; }
  .tk-calendar__banner-container .banner-text {
    position: absolute;
    top: 16px;
    left: 53px;
    color: black;
    width: 214px;
    font: 700 26px/26px "Circular Std", "Circular Std";
    height: 52px; }
  .tk-calendar__banner-container .banner_google-play-image {
    position: absolute;
    top: 162px;
    left: 27px;
    width: 108px;
    height: 32px; }
  .tk-calendar__banner-container .banner_apple-store-image {
    position: absolute;
    top: 167px;
    left: 196px;
    width: 96px;
    height: 32px; }
  .tk-calendar__banner-container .inside-image {
    width: 100%;
    height: auto;
    object-fit: cover; }

.tk-calendar__wrapper {
  border-radius: 5px; }

.tk-calendar__header {
  display: flex;
  font-size: 16px;
  background-color: #f7f9ff;
  z-index: 2; }

.tk-calendar__title {
  display: flex;
  color: #873bff;
  font-family: "Circular Std", "Circular Std";
  margin: 0 0 16px 0;
  height: 48px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 24px; }
  .tk-calendar__title span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 16px;
    min-width: 120px; }

.tk-calendar__btn-filter {
  display: none; }

.tk-calendar__btn-newbook {
  display: none; }

.tk-calendar__button {
  padding: 0;
  outline: none;
  cursor: pointer;
  min-height: 45px; }
  .tk-calendar__button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #a5a4a4;
    cursor: pointer; }
  .tk-calendar__button--previous:before {
    content: "\e932";
    margin: 0 15px; }
  .tk-calendar__button--next:before {
    content: "\e931";
    margin: 0 15px; }

.tk-calendar__tabs-wrapper {
  margin: 0 0 16px 24px;
  padding: 0 24px;
  display: inline-flex;
  gap: 24px;
  width: 168px;
  background: #fff;
  height: 48px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 24px;
  overflow: hidden; }
  .tk-calendar__tabs-wrapper > li {
    list-style: none;
    cursor: pointer;
    width: 50%; }

.tk-calendar__tabs-button {
  border: 0;
  padding: 0;
  cursor: pointer;
  font: 1rem/1.4375rem "Circular Std", "Circular Std";
  border-radius: 4px;
  background: #fff;
  color: #969696;
  height: 100%;
  width: 100%;
  outline: none;
  margin: auto; }
  .tk-calendar__tabs-button:hover {
    color: #873bff; }
  .tk-calendar__tabs-button.selected {
    pointer-events: none;
    cursor: pointer;
    background: #fff;
    color: #873bff; }

.tk-dialog__wrapper {
  position: absolute;
  min-height: 150px;
  width: 387px;
  left: calc(100% + 10px);
  top: -2px;
  box-shadow: 0 0 18px 0 rgba(197, 197, 197, 0.65);
  border-radius: 5px;
  z-index: 2;
  padding: 23px 35px;
  background: #f7f9ff;
  cursor: default; }
  .tk-dialog__wrapper--before {
    right: 100%;
    top: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #fff;
    border-width: 5px; }

.tk-dialog__btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px !important;
  height: 56px;
  z-index: 4; }
  .tk-dialog__btn-close:before {
    content: "\e926";
    color: #000;
    font-size: 16px; }

.tv-general-customTable {
  width: 100%;
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 1px 5px; }
  .tv-general-customTable__wrapper {
    position: relative;
    margin-top: 30px;
    min-height: 200px; }
  .tv-general-customTable__wrapper-scroll {
    max-height: 350px;
    overflow: auto;
    margin-top: 20px; }
  .tv-general-customTable > thead > th {
    margin: 0 10px;
    padding: 10px 10px; }
  .tv-general-customTable > thead > tr {
    position: absolute;
    z-index: 16;
    top: -20px;
    font: 0.875rem/1.75rem "Circular Std", sans-serif;
    font-weight: 300;
    letter-spacing: -0.2px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    height: 50px;
    background-color: #ffffff; }
    .tv-general-customTable > thead > tr th {
      margin: 0 10px;
      padding: 10px 10px; }
  .tv-general-customTable > tbody {
    margin-top: 70px; }
    .tv-general-customTable > tbody > tr:nth-child(1) {
      height: 10px; }
    .tv-general-customTable > tbody > tr {
      font: 0.725rem/1.75rem "Circular Std", sans-serif;
      font-weight: 100;
      letter-spacing: -0.2px;
      box-shadow: 0 10px 18px -12px #74767d;
      height: 50px;
      background-color: #ffffff; }
      .tv-general-customTable > tbody > tr > td:nth-child(1) {
        text-align: center; }
  .tv-general-customTable__badge {
    position: relative;
    width: 60px;
    border-radius: 5px;
    text-transform: uppercase;
    font: 0.675rem "Circular Std", sans-serif;
    margin: 5px;
    text-align: center;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29); }
    .tv-general-customTable__badge.date-timeSlot {
      width: 150px;
      font-size: 0.775rem;
      margin: 5px 15px; }
    .tv-general-customTable__badge.timeSlot {
      width: 80px;
      margin: 5px 15px; }
      .tv-general-customTable__badge.timeSlot .tv-customTable__badge-edit-icon.fa-pencil::before {
        content: ""; }
    .tv-general-customTable__badge-available {
      color: #6566b9; }
    .tv-general-customTable__badge-unavailable {
      color: #873bff; }
    .tv-general-customTable__badge.active {
      text-align: left;
      background-color: #fff; }
    .tv-general-customTable__badge.inactive {
      text-align: left;
      color: #c2c2c5;
      background-color: #757576cd; }
    .tv-general-customTable__badge.edit {
      min-height: 24px;
      width: 80px;
      cursor: pointer;
      text-align: right; }
      .tv-general-customTable__badge.edit-icon-only {
        width: 180px;
        padding-top: 6px;
        box-shadow: 0px 0px 0px 0px #75767c4a; }
      .tv-general-customTable__badge.edit.active {
        background-color: #fff; }
        .tv-general-customTable__badge.edit.active > div {
          left: 3px;
          top: 4px;
          position: absolute; }
          .tv-general-customTable__badge.edit.active > div:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid #873bff;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: #873bff; }
      .tv-general-customTable__badge.edit.inactive {
        color: #000;
        background-color: #fff; }
        .tv-general-customTable__badge.edit.inactive > div {
          left: 3px;
          top: 4px;
          position: absolute; }
          .tv-general-customTable__badge.edit.inactive > div:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid #873bff;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: #fff; }
  .tv-general-customTable__defaultAvailability > tbody td {
    margin-top: 10px;
    margin-bottom: 10px; }
  .tv-general-customTable__defaultAvailability .disabled-func {
    color: #fff !important;
    cursor: not-allowed;
    background-color: #757576cd; }
  .tv-general-customTable__defaultAvailability .tv-display-flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }
  .tv-general-customTable__defaultAvailability > thead tr {
    width: 100%; }
    .tv-general-customTable__defaultAvailability > thead tr th {
      margin-top: auto;
      margin-bottom: 10px; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(1) {
      width: 40vw; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(2) {
      width: 40vw; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(3) {
      width: 20vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(1) {
    padding-left: 10px;
    width: 40vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(2) {
    padding-left: 10px;
    width: 40vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(3) {
    width: 20vw; }
    .tv-general-customTable__defaultAvailability > tbody td:nth-child(3) > div {
      justify-content: space-around; }
  .tv-general-customTable__button {
    width: 30px;
    border-radius: 6px;
    font: 0.875rem "Circular Std", sans-serif;
    line-height: 0.4rem;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #873bff;
    color: #fff;
    padding: 6px 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29); }
    .tv-general-customTable__button .disabled-func {
      color: black !important;
      cursor: not-allowed;
      background-color: #75767c4a; }
    .tv-general-customTable__button-submit {
      width: 80px;
      padding: 10px 5px;
      background-color: #873bff; }
    .tv-general-customTable__button-cancel {
      width: 80px;
      padding: 10px 5px;
      color: #873bff;
      background-color: #fff; }
    .tv-general-customTable__button-flow {
      width: 20px;
      box-shadow: 0px 0px 0px 0px #75767c4a;
      padding: 10px 5px;
      background-color: #873bff; }
  .tv-general-customTable__default-nodata {
    width: 100%;
    font: 0.875rem/1.75rem "Circular Std", sans-serif;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    height: 50px;
    background-color: #ffffff; }

.tk-preference-title {
  background: transparent;
  font: 500 1.5rem/2rem "Circular Std", sans-serif;
  letter-spacing: -0.2px;
  color: #353535;
  margin: 15px auto 15px auto;
  width: 80%; }

.tk-preference__submit {
  position: fixed;
  bottom: 3px;
  right: 12%;
  background: transparent; }

.tk-preference__wrapper {
  width: 80%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0px auto 0 auto;
  min-height: 65vh; }
  .tk-preference__wrapper label {
    font: 0.875rem/1rem "PT Serif", serif;
    cursor: pointer; }
  .tk-preference__wrapper input[type="checkbox"] {
    visibility: hidden; }
  .tk-preference__wrapper input[type="checkbox"] + label:before {
    border: 1px solid #873bff;
    content: "\00a0";
    display: inline-block;
    font: 700 14px/1em sans-serif;
    height: 16px;
    margin: 0 0.5em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px; }
  .tk-preference__wrapper input[type="checkbox"]:checked + label:before {
    background: #873bff;
    color: #fff;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:checked:disabled + label:before {
    background: #b9b3b3;
    color: #a3a3a3;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:disabled + label:before {
    background: #b9b3b3;
    color: #b9b3b3;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:checked + label:after {
    font-weight: bold; }
  .tk-preference__wrapper input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px; }

.tk-preference-subtitle {
  background: transparent;
  font: 500 0.975rem "Circular Std", sans-serif;
  letter-spacing: -0.2px;
  color: #353535;
  margin: 15px 0px 15px 0px; }

.tk-preference .fixed_collapse {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 90vh; }

.tk-preference__container-scroll {
  height: 65vh !important;
  width: 100% !important;
  border-top: 1px solid #f1f1f1; }
  .tk-preference__container-scroll > div:nth-child(1) {
    height: 65vh;
    overflow-x: hidden !important; }
  .tk-preference__container-scroll > div:nth-child(2) {
    height: 50vh; }
    .tk-preference__container-scroll > div:nth-child(2) > div {
      display: none !important; }
  .tk-preference__container-scroll > div:nth-child(3) {
    overflow: hidden !important; }

.tk-preference__contract-group {
  width: 50%; }
  .tk-preference__contract-group > div {
    width: 250px;
    border-radius: 10px; }

.tk-left-drawer {
  position: absolute;
  top: 120px;
  left: 10px;
  min-height: 650px;
  max-height: 1000px;
  min-width: 320px;
  padding-top: 30px;
  transform: translateX(-100%);
  animation: slide-in 0.5s forwards;
  background-color: #f7f9ff;
  z-index: 50; }
  .tk-left-drawer__close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer; }
    .tk-left-drawer__close-icon::before {
      font-size: 14px;
      font-weight: 400; }
  .tk-left-drawer .tk-dialog__wrapper {
    box-shadow: none; }
    .tk-left-drawer .tk-dialog__wrapper .tk-dialog__wrapper--before {
      display: none !important; }
  .tk-left-drawer .tk-dialog__body {
    height: 100%; }

.tk-assignment-detail__container {
  left: 0;
  top: 0;
  width: calc(100% - 10px);
  height: 625px !important;
  border: 1px solid #e0e0e0;
  background-color: #fbfbfb;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px; }
  .tk-assignment-detail__container .tk-dialog__btn-close {
    position: absolute;
    right: 0;
    font-family: "Circular Std", "Circular Std";
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4;
    position: absolute; }
  .tk-assignment-detail__container .icon-close::before {
    font-size: 0.8rem !important;
    font-weight: 700 !important; }

.tk-assignment-detail__drawer {
  z-index: 5; }

.tk-assignment-list-box__container {
  left: 0;
  top: 0;
  width: calc(100% - 10px);
  height: 550px !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px; }
  .tk-assignment-list-box__container .tk-dialog__btn-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4; }
  .tk-assignment-list-box__container .icon-close::before {
    font-size: 0.8rem !important;
    font-weight: 700 !important; }

.tk-assignment-list-box_header {
  font: 18px/23px "Circular Std", "Circular Std";
  padding: 10px 10px 10px 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: capitalize; }

.tk-assignment-list-box_drawer {
  height: 74vh; }

.tk-assignment-list-box_body {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column; }
  .tk-assignment-list-box_body-text {
    padding: 5px;
    color: #252525;
    font: 14px/18px "Circular Std", "Circular Std"; }
  .tk-assignment-list-box_body-scroll {
    height: calc(100% - 80px) !important; }

.tk-assignment-list-box_footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
  padding: 0 12px; }

.tk-assignment-list-box__event-item {
  font: 0.6875rem/1rem "Circular Std", "Circular Std";
  position: absolute;
  background: aliceblue;
  padding: 2px 5px 2px 5px;
  width: calc(100% + 2px);
  left: -1px;
  cursor: pointer;
  border-radius: 3px;
  line-height: 12px;
  color: #873bff; }
  .tk-assignment-list-box__event-item--available {
    color: #ff9b4e;
    border: 1px solid #ff9b4e; }
  .tk-assignment-list-box__event-item--accepted {
    color: #6478e6;
    border: 1px solid #6478e6; }
  .tk-assignment-list-box__event-item--rejected {
    color: #e94242;
    border: 1px solid #e94242; }
  .tk-assignment-list-box__event-item--losted {
    color: #696969;
    border: 1px solid #696969; }
  .tk-assignment-list-box__event-item--traveling, .tk-assignment-list-box__event-item--working {
    color: #fbbcdb;
    border: 1px solid #fbbcdb; }
  .tk-assignment-list-box__event-item--fullfilled {
    color: #fff;
    border: 1px solid #18ad79; }
  .tk-assignment-list-box__event-item--done {
    color: #ffa500;
    border: 1px solid #ffa500; }
  .tk-assignment-list-box__event-item--cancelled {
    color: #74737b;
    border: 1px solid #74737b; }
  .tk-assignment-list-box__event-item--submitted {
    color: #008000;
    border: 1px solid #008000; }
  .tk-assignment-list-box__event-item--custom {
    border: 1px solid #9c69ad;
    color: #9c69ad; }

.tk-assignment-list-box__event-accepted {
  background-color: #50c427 !important; }

.tk-assignment-list-box__event-working {
  background-color: #6aa5fe !important; }

.tk-assignment-list-box__event-fullfilled {
  background-color: #b4b4b4 !important; }

.tk-assignment-list-box__event-submitted {
  background-color: #fa0960 !important; }

.tk-assignment-list-box__event-cancelled {
  background-color: #fa0960 !important; }

.tk-assignment-list-box__event-available {
  background-color: #2b7af1 !important; }

.tk-assignment-list-box__event-single {
  color: #74737b;
  background-color: #fff;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  margin: 8px;
  padding: 9px; }
  .tk-assignment-list-box__event-single_container1 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Circular Std", "Circular Std";
    margin-bottom: 5px; }
    .tk-assignment-list-box__event-single_container1 .header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
      font: 400 14px/18px "Circular Std", "Circular Std"; }
      .tk-assignment-list-box__event-single_container1 .header .notification {
        color: #873bff; }
    .tk-assignment-list-box__event-single_container1 .skill {
      margin-bottom: 8px; }
    .tk-assignment-list-box__event-single_container1 .status {
      font-size: 8px;
      background: white;
      line-height: 20px;
      border-radius: 13px;
      color: black;
      padding: 1px 8px 0px;
      width: -moz-fit-content;
      width: fit-content;
      text-transform: uppercase; }
    @media (max-width: 1024px) {
      .tk-assignment-list-box__event-single_container1 .skill {
        overflow: hidden;
        text-overflow: ellipsis; } }
  .tk-assignment-list-box__event-single_container2 {
    display: flex;
    flex-wrap: wrap;
    font-family: "Circular Std", "Circular Std"; }
    .tk-assignment-list-box__event-single_container2 .fields {
      font: 400 12px/14px "Circular Std", "Circular Std";
      margin: 0 auto 10px 0;
      display: flex;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      letter-spacing: 0.4px;
      align-items: flex-start;
      width: 50%; }
      .tk-assignment-list-box__event-single_container2 .fields i {
        font-size: 16px; }
      .tk-assignment-list-box__event-single_container2 .fields:last-child {
        margin-bottom: 0px; }
      .tk-assignment-list-box__event-single_container2 .fields .briefcase {
        width: 16px;
        height: 16px;
        margin-right: 8px; }
  .tk-assignment-list-box__event-single .time:before {
    color: white;
    margin-right: 10px; }
  .tk-assignment-list-box__event-single .contactPerson:before {
    color: white;
    margin-right: 10px; }

.tk-assignment-list-box__event-content {
  height: 100%;
  overflow: hidden;
  line-height: 1.4rem; }

.tk-assignment-list-box__event-time {
  font-size: 0.5rem;
  font-weight: bold; }

.stepper__item {
  flex: 100% 1;
  position: relative;
  height: 50px;
  background: #e0e0e0;
  margin: 0 0 0 -12px;
  border-radius: 4px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  border: 1px solid #e0e0e0; }
  .stepper__item.current {
    background: #fff;
    font-weight: bold; }
  .stepper__item > div {
    z-index: 3;
    width: 100%;
    padding-right: 5px;
    font-size: 12px;
    font-family: "Circular Std", "Circular Std";
    position: absolute;
    top: 32%; }
  .stepper__item:before {
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    background: #fff;
    display: block;
    position: absolute;
    border-radius: 4px;
    top: 1px;
    left: 2px; }
  .stepper__item.complete {
    background: #873bff; }
    .stepper__item.complete::before {
      background: #873bff; }
    .stepper__item.complete > div {
      color: #fff; }
  .stepper__item:first-child {
    clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%); }
  .stepper__item:last-child {
    clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%); }
    .stepper__item:last-child::before {
      clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%); }

@media (max-width: 768px) {
  .tk-calendar__mobile-fixed-bottom {
    position: fixed;
    background-color: #fff;
    display: flex;
    left: 0;
    bottom: 0;
    width: 100vw; }
    .tk-calendar__mobile-fixed-bottom i.fa {
      -webkit-text-stroke: 1px #873bff; }
  .tk-calendar__btn-newbook {
    font-family: "Circular Std", "Circular Std";
    display: flex;
    background-color: #873bff;
    color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    max-height: 48px;
    justify-content: center;
    padding: 14px 10px;
    width: 50%;
    align-self: center;
    margin: 16px 16px 16px 0; }
  .tk-calendar__btn-filter {
    font-family: "Circular Std", "Circular Std";
    justify-content: space-around;
    display: flex;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 14px 0;
    align-self: center;
    width: 50%;
    margin: 16px; }
  .tk-calendar__header {
    display: flex;
    justify-content: center;
    line-height: 16px;
    font-size: 12px !important;
    margin-bottom: 15px; }
    .tk-calendar__header button.tk-calendar__tabs-button {
      font-size: 14px !important; }
    .tk-calendar__header .tk-calendar__title {
      margin: 0px; }
      .tk-calendar__header .tk-calendar__title span {
        min-width: 90px; }
    .tk-calendar__header .tk-calendar__tabs-wrapper {
      position: relative;
      margin: 0px 0px 0px auto; }
  .tk-calendar__main-title {
    font-family: "Circular Std", sans-serif;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between; }
  .tk-left-drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 50; }
    .tk-left-drawer__close-icon {
      top: 15px; }
    .tk-left-drawer .tv-drawer_buttons {
      width: calc(100vw - 20px);
      bottom: 30px; }
      .tk-left-drawer .tv-drawer_buttons .tv_dashboard__add-button {
        margin-bottom: 0; }
  .tk-assignment-list-box__container,
  .tk-assignment-detail__container {
    height: 100vh !important;
    width: 100vw;
    border-radius: 0; }
  .tk-assignment-list-box_body,
  .tk-assignment-detail_body {
    height: calc(100vh - 50px) !important;
    padding: 0px;
    border-top: none; }
  .tk-assignment-list-box_body-scroll,
  .tk-assignment-detail_body-scroll {
    height: calc(100% - 130px) !important; }
  .tv-advanced-order__scroll-container {
    height: calc(100vh - 125px) !important; }
    .tv-advanced-order__scroll-container > div {
      overflow-x: hidden !important; }
    .tv-advanced-order__scroll-container .tv-checkbox .custom-control-input ~ .custom-control-label {
      font: 400 16px/18px "Circular Std", sans-serif !important;
      font-size: 16px !important;
      letter-spacing: 0px !important;
      text-transform: capitalize !important;
      background-color: #fff !important; }
  .tv-workassignment__modal {
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    position: fixed;
    height: 100vh; }
    .tv-workassignment__modal .tv-modal__overlay {
      top: 0 !important;
      left: 0 !important; }
    .tv-workassignment__modal-scroll-container, .tv-workassignment__modal-container {
      min-width: 100% !important;
      height: auto;
      overflow-y: scroll;
      margin-bottom: 150px; }
    .tv-workassignment__modal-detail-container {
      min-width: 100% !important;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden; }
    .tv-workassignment__modal-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0 10px;
      width: 100vw;
      background-color: #fff; }
    .tv-workassignment__modal .tv-modal__holder {
      width: calc(100vw);
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      margin-top: 0;
      top: 0;
      border-radius: 0; }
    .tv-workassignment__modal .tv-workassignment__modal-body {
      height: calc(100vh - 140px);
      height: calc(100svh - 140px); }
    .tv-workassignment__modal .tv-workassignment__modal-status > div:nth-child(1) {
      text-align: left;
      width: 55%; }
  .tv-single-assign-form__preview-property .property-value.edit-field {
    padding: 5px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; } }

@media (max-width: 1024px) {
  .tv-right-status {
    padding: 0 32px 0 24px !important; }
  .filter-border {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 16px; }
  .tv-display-mobile-hide.tabs {
    display: none !important; }
  .tv-display-mobile-show.tabs {
    display: flex; } }

.tv-youravailability__wrapper {
  margin-top: 32px; }

.tv-youravailability__list {
  flex-wrap: wrap; }
  .tv-youravailability__list-item {
    position: relative;
    cursor: default;
    margin: 0 1.5% 16px 0;
    font-family: "Circular Std", "Circular Std";
    width: 31%;
    box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }
    .tv-youravailability__list-item-drawer {
      position: relative;
      cursor: default;
      margin: 0 1.5% 16px 0;
      font-family: "Circular Std", "Circular Std";
      width: 90%;
      box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__container {
  padding: 24px;
  width: 100%; }

.tv-youravailability__item-hr {
  border: 0.5px solid rgba(223, 223, 223, 0.5); }

.tv-youravailability__item-space {
  padding-left: 5px; }

.tv-youravailability__item-label {
  color: #a9a9a9;
  font: 0.875rem/1.5rem;
  letter-spacing: 0.3; }

.tv-youravailability__item-text {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  letter-spacing: 0.3; }
  .tv-youravailability__item-text-mainlables {
    color: #873bff;
    font: 300 0.875rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    min-height: 56px; }
  .tv-youravailability__item-text-datetime {
    color: #873bff;
    font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    min-height: 56px; }

.tv-youravailability__item-menu {
  cursor: pointer;
  padding-top: 19px;
  position: absolute;
  top: 0;
  right: 10px; }
  .tv-youravailability__item-menu-button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 1.5rem; }

.tv-youravailability__dropdown .tv-filter-list__menu {
  width: 90px;
  margin: 0;
  right: 0;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__dropdown .tv-buttons__type {
  padding: 10px;
  text-align: left; }

.dashboard__report-container {
  display: flex;
  margin-top: 18px; }

.dashboard__filter-holder {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 24px;
  background-color: #f7f9ff;
  align-items: center; }
  .dashboard__filter-holder .tv-buttons__element {
    margin-top: 8px;
    border-radius: 100px;
    width: 100%; }
  .dashboard__filter-holder .info-message {
    font-weight: 450;
    font-size: 14px;
    font-style: italic; }
  .dashboard__filter-holder .total-assignments {
    font-weight: 400;
    font-size: 16px; }
  .dashboard__filter-holder .error-message {
    font-size: 12px;
    font-weight: 450;
    color: #873bff;
    margin-top: -16px; }

.dashboard__order-count-container {
  flex: 1;
  color: black; }

.dashboard__report-holder {
  margin-left: 22px;
  flex: 3;
  display: flex;
  flex-direction: column;
  font-family: 'Circular Std', sans-serif;
  padding-right: 15px; }

.dashboard__chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 32px;
  margin-bottom: 32px; }

.dashboard__single-chart-container {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  flex-basis: 31%; }

.dashboard__display-card {
  padding: 10px 5px;
  display: flow-root;
  width: 100%;
  height: auto;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
  .dashboard__display-card_nodata {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.65); }

.dashboard__samll-display-card {
  padding: 10px 5px;
  display: grid;
  height: auto;
  width: 100%;
  align-items: center;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__samll-display-card:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
    .dashboard__samll-display-card-header span {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.dashboard_samll-detail-card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto; }
  .dashboard_samll-detail-card-header {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 15px; }
    .dashboard_samll-detail-card-header span {
      width: 190px;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis; }
  .dashboard_samll-detail-card-content {
    display: grid;
    width: 100%;
    height: fit-content;
    max-height: 2rem;
    font-weight: 100;
    float: left;
    background-color: white;
    color: #716464;
    font-size: 1rem;
    padding: 15px; }

.report-card-header {
  font-family: 'Circular Std', sans-serif;
  font-size: 1rem; }

.round-border {
  border-radius: 10px; }

.p-1 {
  padding: 5px; }

.auto-row-1 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto;
  -ms-grid-rows: minmax(min-content, 1fr); }

.auto-row-2 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto auto;
  -ms-grid-rows: minmax(min-content, 1fr) 2px minmax(min-content, 1fr); }

.auto-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto;
  grid-row-gap: 15px; }

.auto-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  grid-gap: 20px; }

.exact-col-4 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-gap: 10px; }

.exact-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-gap: 20px; }

.exact-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-gap: 20px; }

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05); }

.float-filter {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  position: fixed;
  z-index: 1000000;
  bottom: 35%;
  right: 8%; }
  .float-filter-icon-box {
    background-color: #873bff;
    border-radius: 10px 10px 0px 10px;
    width: 60px;
    color: white;
    height: 60px;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 0px 0px #0000001a, 0 3px 6px #0000001a; }
    .float-filter-icon-box span {
      font-size: 2rem;
      padding: 10px; }
  .float-filter-content {
    background-color: #873bff;
    color: white;
    align-self: end;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    height: 40px;
    width: 100%;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 3px 6px #0000001a, 0 0px 0px #0000001a; }
    .float-filter-content span {
      float: left;
      padding: 5px; }
  .float-filter-clear-btn {
    width: auto;
    border-left: 3px white solid;
    height: auto;
    cursor: pointer;
    font-size: 1.4rem; }

.text-float-right {
  padding-right: 5% !important;
  max-width: 200px !important;
  text-align: right !important; }

.report___sidemenu-scroll {
  height: 200px !important;
  width: inherit !important; }

@media (max-width: 768px) {
  .tv-left-pannel__wrapper {
    display: none; }
  .tv-right-pannel-wrapper .tv-right-status {
    padding: 22px 32px; }
    .tv-right-pannel-wrapper .tv-right-status .tv-avatar {
      width: 38px;
      display: block;
      margin-right: 10px; } }

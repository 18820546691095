@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-booknow {
  text-align: left;

  &__container {
    background-color: $WHITE_COLOR;
    padding: 18px 6px;
  }
  &_row {
    padding: 0px 12px;
    .rc-time-picker-input {
      border: 1px solid #e0e0e0;
    }
    padding-left: 0px;
    @media screen and (max-width: 768px) {
      // margin-top: 20px;
      padding-left: 0px;
    }
  }
  &_col {
    padding: 8px 8px 0px !important;
  }
  &__link {
    color: $PRIMARY_COLOR;
    &:hover {
      color: $PRIMARY_COLOR;
      text-decoration: underline !important;
    }
  }
  &__title {
    padding: 25px 0 0 0;
    align-items: center;
    justify-content: center;
    font: 400 24px/20px $PRIMARY_FONT;

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font: 400 16px/20px $PRIMARY_FONT;
      margin-top: 15px;
      text-align: center;
      height: 40px;
      padding: auto 16px;

      &.text-medium {
        font: 300 rem-calc(22) / rem-calc(40) $PRIMARY_FONT;
      }
    }
  }
  &__text {
    font-family: $PRIMARY_FONT;
    font-size: 12px;
    color: $BLACK_COLOR;
    font-weight: 450;
    line-height: 15px;
    margin-top: 14px;
    &-date {
      font-family: $PRIMARY_FONT;
      margin-top: 16px;
      margin-bottom: 28px;
      color: #252525;
    }
    &-unit {
      font-family: $PRIMARY_FONT;
    }
  }

  &__modal {
    top: 0 !important;
    left: -11px !important;
    height: 100vh !important;
    position: absolute;
    z-index: 62;
    &-hidden {
      display: none;
    }
    .form-group {
      margin-bottom: 8px !important;
    }
    &-cancel {
      z-index: 62;
      padding-bottom: 24px;
      &__container {
        padding: 0 10px 24px;
      }
      .tv-modal__holder {
        overflow-y: unset;
      }
    }
    &-header,
    &-cancel-header {
      font: 500 18px/20px $PRIMARY_FONT;
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: $BLACK_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      letter-spacing: 0.2px;
    }
    .title {
      display: flex;
      width: 100%;
      justify-content: center;
      font-family: $PRIMARY_FONT;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 20px;
      margin-top: 48px;
      margin-bottom: 28px;
      color: $COMMON_TEXT_COLOR;
    }
    .text {
      font-size: 18px;
      color: #74737b;
      text-align: center;
      margin-bottom: 30px;
      font-family: $PRIMARY_FONT;
    }
    .tv-modal__overlay {
      top: -175px !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset;
      border-radius: 4px;
    }

    .tv-modal__holder {
      border-radius: 8px;
      min-width: 35vw;
      max-width: max-content;
      background-color: #fff;
      width: 800px;
      max-height: unset;
      top: 63px;
      background-color: #fff;
      overflow-x: hidden;

      &.booknow-holder {
        width: 672px;
        padding: 140px 48px 36px;
        border-radius: 24px;
        max-width: unset;

        .message-container {
          padding: 18px 72px 0;

          .icon-holder {
            width: 96px;
            height: 96px;
            margin: 24px auto;

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }

          .tv-booknow__form__buttons {
            padding: 0;

            & button {
              margin: 0 64px;
            }

            .contact-link {
              text-decoration: none;
              display: block;
              margin: auto;
              padding: 14px 44px;
              background-color: $SECONDARY_COLOR;
              border-radius: 100px;
              color: $WHITE-COLOR;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }

      .tv-modal__btn-close {
        top: 6px;
        right: 6px;
        &::before {
          color: $WHITE-COLOR;
        }
      }

      &__custom-header {
        position: absolute;
        width: 100%;
        height: 140px;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: $BRIGHT_PINK_COLOR;
        display: flex;
        align-items: center;
        gap: 36px;
        padding-left: 46px;

        img {
          width: 72px;
          height: auto;
          object-fit: contain;
        }

        h2 {
          color: $WHITE-COLOR;
          font-weight: 500;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
    &-actions {
      &-button {
        &_cancel {
          margin-top: 24px;
          border: 1px solid $BORDER_COLOR;
          border-radius: 4px;
          width: 100%;
          height: 48px;
          font: 400 16px/20px $PRIMARY_FONT;
          padding: 10px;
          background: #fff;
          margin: 20px 0 2px;
        }

        &_submit {
          margin-top: 24px;
          border: none;
          font: 400 16px/20px $PRIMARY_FONT;
          border-radius: 4px;
          width: 100%;
          height: 48px;
          padding: 10px;
          color: $WHITE_COLOR;
          background-color: $PRIMARY_COLOR;
          margin: 20px 0 2px;
        }
      }
    }
  }

  &__form {
    margin-top: 12px;
    width: 100%;

    &__buttons {
      padding: 24px 0 10px 0;
      button {
        flex: 1;
        margin: 0 8px;
        border-radius: 100px;
      }
    }

    & .dropdown {
      height: 42px;

      & .rc-time-picker-input {
        height: inherit;
        font: 400 16px/20px $PRIMARY_FONT !important;
        &::-ms-clear {
          display: none;
        }
      }

      ul.panel {
        scroll-behavior: smooth;
        padding-left: 15px;
        top: 50px;
        li {
          font: 100 13px/16px $PRIMARY_FONT !important;
          height: 40px;
          padding: 14px 0;
        }
      }
      & .rc-time-picker-select-option {
        height: 48px;
        width: 39px;
        position: absolute;
        right: 0;
        cursor: pointer;

        &::after {
          content: '';
          top: 20px;
          display: inline-block;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid #888888;
        }
      }
    }
    & .tv-date-range-picker__dialog {
      left: 0;
    }
    &.side .tv-date-range-picker__dialog {
      position: fixed;
      left: 25px;
      margin-top: 2px;
    }
    & .tv-form__date-field {
      font: 400 16px/20px $PRIMARY_FONT !important;
      padding: 14px 16px !important;

      &:after {
        top: 12px !important;
      }
    }

    & .tv-form__control--error {
      margin-bottom: 0;
      border: 2px solid red !important;
    }
    & .tv-form__control--error--timePicker {
      & input {
        margin-bottom: 0;
        border: 2px solid red !important;
      }
    }

    & .tv-single-assign-form__dropdownlist {
      padding: 10px 10px !important;
      background-image: linear-gradient(45deg, transparent 50%, #696767 50%),
        linear-gradient(135deg, #696767 50%, transparent 50%),
        linear-gradient(to right, white, white);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat;

      &:after {
        content: '\e933';
        position: absolute;
        font-family: 'transvoice-icon';
        top: 12px;
        left: 2px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__container {
      height: 100vh;
    }
    &_col {
      padding: 8px 0 !important;
    }
    &__text {
      font-family: $PRIMARY_FONT;
      font-size: 16px;
      color: #74737b;
      &-date {
        font-family: $PRIMARY_FONT;
        margin-top: 16px;
        color: #252525;
      }
      &-unit {
        font-family: $PRIMARY_FONT;
      }
    }
    &__container {
      margin-top: 12px;
      padding: 18px 6px;
    }
    &__form {
      margin-bottom: 0;
      form {
        padding-bottom: 82px;
      }
      .p-2 {
        padding: 0.5rem 0 !important;
      }

      .row {
        margin: 0;
      }
    }
    &__modal {
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      position: fixed;
      height: 100vh;
      .tv-modal__holder {
        position: fixed;
        top: 0;
        max-height: 100vh;
        height: 100vh;
        position: absolute;
        position: relative;
        min-width: 270px !important;
        width: 100%;
        max-width: unset;
        .tv-modal__btn-close {
        }

        &.booknow-holder {
          border-radius: 0;
          padding-left: 16px;
          padding-right: 16px;

          .message-container {
            padding: 36px 16px;
            display: flex;
            flex-direction: column;

            .tv-booknow__form__buttons button {
              margin: 0 32px;
            }
          }

          .tv-booknow__loading-container {
            height: 100%;
            justify-content: center;
          }

          .tv-available-interpreters__container {
            &.message-container {
              padding: 18px 16px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            .tv-available-interpreters-description {
              margin-top: 20px;
              gap: 16px;
            }

            .tv-available-interpreters-slot-container {
              column-gap: 12px;
              margin-bottom: 12px;
              .tv-available-interpreters__row {
                width: 48%;
                align-items: center;

                .user-icons {
                  .fa-user:not(:first-of-type) {
                    display: none;
                  }
                }
              }
            }
          }

          .tv-booknow-complete__container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;

            .tv-booknow-complete__interpreter-details {
              font-size: 18px;
            }
            .tv-booknow-complete__data-container {
              width: 100%;
            }
            .tv-booknow-complete__data-footer-text {
              font-size: 14px;
            }
          }
          .tv-booknow-complete__buttons {
            margin-top: 16px;
            button {
              width: 70%;
            }
          }
        }
      }

      &-cancel {
        z-index: 62;
        padding-bottom: 24px;
        .tv-modal__btn-close {
          display: none;
        }
        &-header {
          display: none;
        }
        .title {
          margin-bottom: 24px;
        }
        .text {
          margin-bottom: 4px;
        }
        .tv-modal__overlay {
          top: 0 !important;
          left: 0 !important;
        }

        .tv-modal__holder {
          border-radius: 4px;
          margin: 0 10px;
          max-width: 400px;
          max-height: unset;
          background-color: #fff;
          overflow-x: hidden;
          z-index: 63;
          padding: 0 10px 24px;
          animation: zoomIn 0.5s;
          max-width: 708px;
          overflow-y: auto;
          height: auto;
          width: 332px;
          padding-bottom: 24px;
        }
      }
    }
  }

  &__loading-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &-title {
      font-size: 20px;
      font-weight: 600;
    }

    &-animation-container {
      height: 250px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      &-images {
        img {
          width: 95px;
          object-fit: contain;
          position: absolute;
          opacity: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .person-1 {
          animation: slide-in-left 2s ease 0s infinite normal forwards,
            hide 12s ease 0s infinite;
        }
        .person-4 {
          animation: slide-out-right 2s ease 2s infinite normal forwards,
            hide 12s ease 2s infinite;
        }
        .person-2 {
          animation: slide-in-left 2s ease 4s infinite normal forwards,
            hide 12s ease 4s infinite;
        }
        .person-5 {
          animation: slide-out-right 2s ease 6s infinite normal forwards,
            hide 12s ease 6s infinite;
        }
        .person-3 {
          animation: slide-in-left 2s ease 8s infinite normal forwards,
            hide 12s ease 8s infinite;
        }
        .person-6 {
          animation: slide-out-right 2s ease 10s infinite normal forwards,
            hide 12s ease 10s infinite;
        }
      }
      &-magnifier {
        max-width: 250px;
        max-height: 250px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.tv-available-interpreters {
  &__container {
    padding: 18px 14px 25px;

    &.message-container {
      padding: 18px 72px 25px;

      .tv-booknow__form__buttons button {
        margin: 0 64px;
      }
    }

    .tv-booknow__form__buttons {
      .back-button {
        color: $PURPLE_BLUE_COLOR;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
        border: none;
      }
    }
  }
  &-description {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__sub-heading {
      font-size: 20px;
      font-weight: 500;
      color: $BLACK_COLOR;
      margin-bottom: 16px;
      font-family: $PRIMARY_FONT;
    }
    &__text {
      font-size: 16px;
      color: $BLACK_COLOR;
      margin-bottom: 32px;
      font-family: $PRIMARY_FONT;
    }
  }
  &__title {
    font-family: $PRIMARY_FONT;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: $BLACK_COLOR;
    margin-top: 22px;
  }

  &__message {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $BLACK_COLOR;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  &__header {
    font-family: $PRIMARY_FONT;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #74737b;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
  }
  &-slot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }
  &__row {
    width: 45%;
    border-radius: 8px;
    font-family: $PRIMARY_FONT;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    color: $BLACK_COLOR;
    background-color: $PURPLE_BLOOD_COLOR;
    box-sizing: border-box;
    &-active {
      box-shadow: inset 0 0 0 2px $PURPLE_BLUE_COLOR;
    }

    .user-icons {
      display: flex;

      .plus {
        font-size: 14px;
        font-weight: 600;
        margin-right: 5px;
        line-height: 14px;
      }
    }

    i {
      margin-right: 6px;
      font-size: 12px;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
}

.tv-booknow-complete {
  &__container {
    padding: 0 14px;
    hr {
      margin: 16px 0 24px;
    }
  }
  &__heading {
    font-family: $PRIMARY_FONT;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: $BLACK_COLOR;
    margin-top: 24px;
  }
  &__interpreter-details {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    font-weight: 450;
    text-align: center;
    color: $BLACK_COLOR;
    margin-top: 16px;
    text-align: center;

    div {
      margin-bottom: 8px;
    }

    a {
      text-decoration: underline;
      font-size: 18px;
      font-weight: 500;
      color: #3772ce;
    }
  }
  &__reference-number {
    font-family: $PRIMARY_FONT;
    margin-top: 28px;
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #74737b;
  }
  &__data {
    &-container {
      border-radius: 20px;
      padding: 24px;
      margin: 16px auto;
      width: 60%;
      box-shadow: 0px 1px 5px 0px #00000029;
    }

    &-table {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &-heading {
      font-family: $PRIMARY_FONT;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: $BLACK_COLOR;
      margin-bottom: 16px;
      text-align: center;
    }

    &-item {
      font-family: $PRIMARY_FONT;
      display: flex;
      padding: 8px 0;
      font-size: 14px;
      color: #535252;
      align-items: center;
      margin-left: 10px;
      &-label {
        font-size: 20px;
        margin-right: 10px;

        svg,
        path {
          width: 20px;
          height: 20px;
          fill: #535252;
        }
      }
      &-value {
        flex: 2;
      }
      &__sent-to {
        margin-top: 16px;
        font-family: $PRIMARY_FONT;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #252525;
      }
    }

    &-footer-text {
      font-size: 12px;
      text-align: center;
      color: #535252;
    }
  }
  &__buttons {
    padding: 16px 0 0;
    display: flex;
    justify-content: center !important;

    button {
      flex: unset;
      width: 50%;
      align-self: center;
    }
  }
}

// .tv-booknow_row {
//   margin-top: 20px;
//   padding-left: 0px;
//   @media screen and (max-width: 768px) {
//     margin-top: 20px;
//     padding-left: 0px;
//   }
// }

@keyframes hide {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  17% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-left {
  0% {
    animation-timing-function: ease-in;
    left: -50%;
    opacity: 1;
  }
  45% {
    animation-timing-function: ease-out;
    left: 50%;
    opacity: 1;
  }
  50% {
    animation-timing-function: ease-in;
    left: 52%;
  }
  55% {
    animation-timing-function: ease-out;
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 50%;
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    left: 50%;
    opacity: 1;
  }
  50% {
    animation-timing-function: ease-out;
    left: 50%;
  }
  100% {
    animation-timing-function: ease-in;
    left: 150%;
    opacity: 1;
  }
}
